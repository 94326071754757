import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { openModal } from "features/modal-slice";
import { ContactUsModal } from "components/modals/Modals";

import styles from "../styles/formBook.module.sass";
import { useTranslation } from "react-i18next";

const FormBook = () => {
  const { t, i18n } = useTranslation();
  
  const formBookBox = i18n.resolvedLanguage === 'ru' ? "formBookBoxRu" : "formBookBox"
  const dispatch = useDispatch();

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  const router = useLocation();
  const trialHref = "/trial";

  return (
    <>
      <div className={styles[formBookBox]}>
        {router.pathname === trialHref ? null : (
          <p>{t('main.formBook.text')}</p>
        )}
        <div className={styles.formBookText} onClick={openContactUsModal}>
          <h2 className={styles.h2}>{t('main.formBook.title')}</h2>
          <svg
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
              fill="#FFF"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default FormBook;
