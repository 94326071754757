import DocTitle from "../../components/Docs/DocTitle";
import DocFooter from "../../components/Docs/DocFooter";
import styles from "./styles/documents.module.sass";
import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
  const {t} = useTranslation();
  return (
    <div className={styles.main}>
      <div className={styles.mainBox}>
        <DocTitle title={t('cookiePolicy.docTitle')} />
        <p>
          {t('cookiePolicy.header.part01')}
        </p>
        <p>
          {t('cookiePolicy.header.part02')}
        </p>
        <p>
          {t('cookiePolicy.header.part03')}
        </p>
        <p>
          {t('cookiePolicy.header.part04')}
        </p>
        <div className={styles.CookieParagraph}>
          <h2 className={styles.h2}>
            {t('cookiePolicy.cookieParagraph01.title')}
          </h2>
          <p>
            {t('cookiePolicy.cookieParagraph01.text')}
          </p>
        </div>
        <div className={styles.CookieParagraph}>
          <div className={styles.section}>
            <h2 className={styles.h2}>
              {t('cookiePolicy.cookieParagraph02.title')}
            </h2>
            <p>
              {t('cookiePolicy.cookieParagraph02.text01')}
            </p>
            <p>
              {t('cookiePolicy.cookieParagraph02.text02')}
            </p>
            <p>
              {t('cookiePolicy.cookieParagraph02.text03')}
            </p>
            <p>
              {t('cookiePolicy.cookieParagraph02.text04')}
            </p>
            <p>
              {t('cookiePolicy.cookieParagraph02.text05')}
            </p>
          </div>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph02.subTitle01')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph02.text06')}
          </p>
          <ul className={styles.cookieLinks}>
            <li>
              <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">
                Google Chrome
              </a>
            </li>
            <li>
              <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a href="https://support.apple.com/en-by/guide/safari/sfri11471/mac">
                Apple Safari
              </a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                Microsoft Internet Explorer
              </a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                Microsoft Edge
              </a>
            </li>
            <li>
              <a href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing">
                Brave
              </a>
            </li>
            <li>
              <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                Opera
              </a>
            </li>
          </ul>
          <p>
            {t('cookiePolicy.cookieParagraph02.text07')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph02.subTitle02')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph02.text08')}
          </p>
        </div>
        <div className={styles.CookieParagraph}>
          <div className={styles.part}>
            <h2 className={styles.h2}>{t('docText.owner')}</h2>
            <p>{t('docText.address')} </p>
            <p>
              {t('docText.contact')}
              <a href="mailto:contacts@cherishdev.com">
                contacts@cherishdev.com
              </a>
            </p>
            <p>
              {t('cookiePolicy.cookieParagraph03.text01')}
            </p>
          </div>
          <h2 className={styles.h2}>{t('cookiePolicy.cookieParagraph03.title01')}</h2>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle01')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text02')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle02')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text03')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle03')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text04')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle04')}</h5>
          <p>{t('cookiePolicy.cookieParagraph03.text05')}</p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle05')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text06')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle06')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text07')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle07')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text08')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle08')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text09')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle09')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text10')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle10')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text11')}
          </p>
          <h5 className={styles.h5}>{t('cookiePolicy.cookieParagraph03.subTitle11')}</h5>
          <p>
            {t('cookiePolicy.cookieParagraph03.text12')}
          </p>
          <h2 className={styles.h2}>{t('cookiePolicy.cookieParagraph03.title02')}</h2>
          <p>
            {t('cookiePolicy.cookieParagraph03.text13')}
          </p>
        </div>
        <DocFooter />
      </div>
    </div>
  );
};

export default CookiePolicy;
