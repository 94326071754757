import useMedia from "../../hooks/useMedia";

import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import MobileNav from "../Nav/MobileNav";
import GetTrial from "components/GetTrial/GetTrial";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => {
  const { isMobile } = useMedia();

  return (
    <>
      <Header />
      <GetTrial />
      {isMobile ? <MobileNav /> : <Nav />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
