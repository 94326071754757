import styles from "./styles/twoArrowBtn.module.sass";

const TwoArrowBtn = ({
  number,
  text,
  arrColor,
  borderColor,
  botBorderColor,
}) => {
  return (
    <div className={styles.btnContainer}>
      <div
        className={styles.btn}
        style={
          borderColor
            ? {
                textShadow: `1px 0 1px ${borderColor}, 0 1px 1px ${borderColor}, -1px 0 1px ${borderColor}, 0 -1px 1px ${borderColor}`,
                borderBottom: `1px solid ${botBorderColor}`,
              }
            : null
        }
      >
        <span
          className={styles.leftArr}
          style={arrColor ? { color: arrColor } : null}
        >
          {"<"}
        </span>
        <p className={styles.btnNumber}>{number}</p>
        <span
          className={styles.rightArr}
          style={arrColor ? { color: arrColor } : null}
        >
          {">"}
        </span>
      </div>
      <div className={styles.text}>{text ? text : null}</div>
    </div>
  );
};

export default TwoArrowBtn;
