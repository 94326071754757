import AboutCards from "./AboutCards";
import PageTitle from "../../UI/pageTitle/PageTitle";
import TwoArrowBtn from "components/UI/twoArrowBtn/TwoArrowBtn";
import {staticLine, staticLineRu} from '../../../images/imgs/index.js';
import styles from "../styles/about.module.sass";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t , i18n} = useTranslation();

  const staticLineSvg = i18n.resolvedLanguage === "ru" ? staticLineRu : staticLine
  const aboutFoundedBox = i18n.resolvedLanguage === "ru" ? "aboutFoundedBoxRu" : "aboutFoundedBox"
  return (
    <div className={styles.aboutBox}>
      <div className={styles.aboutTitle}>
        <PageTitle title={t('main.about.title')} subTitle={t('main.about.subTitle')} />
      </div>
      <div className={styles.aboutTextBox}>
        <h1 className={styles.utpText}>{t('main.about.utpText')}</h1>
        <div className={styles.utp}>
          <p className={styles.utpTitle}>
          {t('main.about.utpTitle')}
          </p>
          <div className={styles.utpPoints}>
            <TwoArrowBtn
              number="01"
              text={t('main.about.utpPoints.frPoint')}
              arrColor="#4EB5FF"
              borderColor="#4EB5FF"
              botBorderColor="#4EB5FF"
            />
            <TwoArrowBtn
              number="02"
              text={t('main.about.utpPoints.scPoint')}
              arrColor="#4EB5FF"
              borderColor="#4EB5FF"
              botBorderColor="#4EB5FF"
            />
            <TwoArrowBtn
              number="03"
              text={t('main.about.utpPoints.thPoint')}
              arrColor="#4EB5FF"
              borderColor="#4EB5FF"
              botBorderColor="#4EB5FF"
            />
            <TwoArrowBtn
              number="04"
              text={t('main.about.utpPoints.ftPoint')}
              arrColor="#4EB5FF"
              borderColor="#4EB5FF"
              botBorderColor="#4EB5FF"
            />
          </div>
        </div>
      </div>
      <div className={styles.staticLineBox}>
        <div className={styles.staticLine}>
          <img src={staticLineSvg} alt="line" />
        </div>
      </div>
      <div className={styles[aboutFoundedBox]}>
        <AboutCards />
      </div>
    </div>
  );
};

export default About;
