import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard } from "swiper";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useMedia from "../../../hooks/useMedia";

import { openModal } from "features/modal-slice";
import PrimaryButton from "../../UI/button/PrimaryButton";
import Banner from "../Banner/Banner";
import { ContactUsModal } from "components/modals/Modals";

import ManualLogo from "../../../images/imgs/ManualLogo.svg";
import AutomationLogo from "../../../images/imgs/AutomationLogo.svg";
import ConsultingLogo from "../../../images/imgs/ConsultingLogo.svg";
import SubscriptionLogo from "../../../images/imgs/SubscriptionLogo.svg";
import Shadow from "../../../images/imgs/DarkEllipse.svg";

import styles from "../styles/mainMobile.module.sass";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import { useTranslation } from "react-i18next";

const MainMobile = () => {
  const { t } = useTranslation();

  const { isMobile } = useMedia();

  const manualList = t('main.manualPoints', { returnObjects: true });

  const automationList = t('main.automationPoints', { returnObjects: true});

  const consultingList = t('main.consultingPoints', { returnObjects: true});
  
  const subscriptionList = t('main.subscriptionPoints', { returnObjects: true});

  const dispatch = useDispatch();

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  return (
    <>
      <div className={styles.mainMobileBox}>
        <div><Banner/></div>
        <div className={styles.mainMobileContent}>
          <Swiper
            speed={1000}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
              bulletActiveClass: styles.processPaginationActive,
            }}
            slidesPerView={1}
            spaceBetween={0}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            autoplay={{
              waitForTransition: true,
              delay: 4000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Keyboard]}
            className={styles.swiper}
          >
            <SwiperSlide className={styles.slide}>
              <p>{t('main.manualText')}</p>
              <div className={styles.logo}>
                <img 
                  src={ManualLogo} 
                  alt="ManualLogo" 
                  loading="eager" />
                {isMobile ? 
                <img 
                  src={Shadow} 
                  alt="shadow" 
                  loading="eager" 
                  className={styles.shadow} /> : null}
                <Link className={styles.manualLink} to="/services/manual-testing">{t('services.manual')}</Link>
              </div>
              <ul>
                {manualList.map((item) => {
                  return <li className={styles.list}
                  key={manualList.indexOf(item)}>{item}</li>;
                })}
              </ul>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <p>{t('main.automationText')}</p>
              <div className={styles.logo}>
                <img
                  src={AutomationLogo}
                  alt="AutomationLogo"
                  loading="eager"
                />
                {isMobile ? 
                <img 
                  src={Shadow} 
                  alt="shadow" 
                  loading="eager" 
                  className={styles.shadow} /> : null}
                <div className={styles.linkLogo}>
                  <Link to="/services/test-automation">{t('services.automation')}</Link>
                </div>
              </div>
              <ul>
                {automationList.map((item) => {
                  return <li key={automationList.indexOf(item)}>{item}</li>;
                })}
              </ul>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <p>{t('main.consultingText')}</p>
              <div className={styles.logo}>
                <img
                  src={ConsultingLogo}
                  alt="ConsultingLogo"
                  loading="eager"
                />
                {isMobile ? 
                <img 
                  src={Shadow} 
                  alt="shadow" 
                  loading="eager" 
                  className={styles.shadow} /> : null}
                <Link to="/services/consulting&audit">{t('services.consulting')}</Link>
              </div>
              <ul>
                {consultingList.map((item) => {
                  return <li key={consultingList.indexOf(item)}>{item}</li>;
                })}
              </ul>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <p>{t('main.subscriptionText')}</p>
              <div className={styles.logo}>
                <img
                  src={SubscriptionLogo}
                  alt="SubscriptionLogo"
                  loading="eager"
                />
                {isMobile ? 
                <img 
                  src={Shadow} 
                  alt="shadow" 
                  loading="eager" 
                  className={styles.shadow} /> : null}
                <Link to="/services/qa-subscription">{t('services.subscription')}</Link>
              </div>
              <ul>
                {subscriptionList.map((item) => {
                  return <li key={subscriptionList.indexOf(item)}>{item}</li>;
                })}
              </ul>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.mybtn} onClick={openContactUsModal}>
          <PrimaryButton text={t('primaryButton')} />
        </div>
      </div>
    </>
  );
};

export default MainMobile;
