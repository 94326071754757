import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";
import { FreeGuideModal } from "components/modals/Modals";
import styles from "./servicesTestingGuide.module.sass";
import { useTranslation } from "react-i18next";

const ServicesTestingGuide = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const guideBox = i18n.resolvedLanguage === 'ru' ? "guideBoxRu": "guideBox"
  const openGuideModal = () => {
    dispatch(openModal(FreeGuideModal));
  };
  return (
    <>
      <div className={styles[guideBox]}>
        <p>{t('servicesPage.servicesTestingGuide.text')}</p>
        <div className={styles.guideText} onClick={openGuideModal}>
          <h2>{t('servicesPage.servicesTestingGuide.title')}</h2>
          <svg
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
              fill="#FFF"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default ServicesTestingGuide;
