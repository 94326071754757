import useMedia from "../../hooks/useMedia";
import About from "../../components/Main/About/About";
import Benefits from "../../components/Main/Benefits/Benefits";
import FormAsk from "../../components/Main/FormAsk/FormAsk";
import FormBook from "../../components/Main/FormBook/FormBook";
import Main from "../../components/Main/Home/Main";
import MainMobile from "components/Main/Home/MainMobile";
import Process from "../../components/Main/Process/Process";
import RecordOfSuccess from "../../components/Main/RecordOfSuccess/RecordOfSuccess";
import Updates from "components/Main/Updates/Updates";
import styles from "../../components/Main/styles/main.module.sass";

const Home = () => {

  const { isDesktop } = useMedia();
  return (
    <div>
      {isDesktop ? (
        <div className={styles.main}>
          <Main />
        </div>
      ) : (
        <MainMobile />
      )}
      <About />
      <Process />
      <Benefits />
      <FormAsk />
      <RecordOfSuccess />
      <FormBook />
      <Updates />
    </div>
  );
};

export default Home;
