import DocText from "../../components/Docs/DocText";
import DocTitle from "../../components/Docs/DocTitle";
import DocFooter from "../../components/Docs/DocFooter";
import styles from "./styles/documents.module.sass";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const {t} = useTranslation();

  return (
    <main className={styles.main}>
      <div className={styles.mainBox}>
        <DocTitle title={t('termsAndConditions.docTitle')} />
        <DocText />
        <div className={styles.content}>
          <h3 className={styles.h3}>
            <span>01</span>{t('termsAndConditions.01Title')}
          </h3>
          <p>
            {t('termsAndConditions.01Point')}
          </p>
          <h3 className={styles.h3}>
            <span>02</span>{t('termsAndConditions.02Title')}
          </h3>
          <p>
            {t('termsAndConditions.2.1Point')}
          </p>
          <p>
            {t('termsAndConditions.2.2Point')}
          </p>
          <h3 className={styles.h3}>
            <span>03</span>{t('termsAndConditions.03Title')}
          </h3>
          <p>{t('termsAndConditions.3.1Point')}</p>
          <p>
            {t('termsAndConditions.3.1.1Point')}
          </p>
          <p>
            {t('termsAndConditions.3.1.2Point')}
          </p>
          <p>{t('termsAndConditions.3.1.3Point')}</p>
          <p>{t('termsAndConditions.3.1.4Point')}</p>
          <p>
            {t('termsAndConditions.3.1.5Point')}
          </p>
          <p>
            {t('termsAndConditions.3.1.6Point')}
          </p>
          <h3 className={styles.h3}>
            <span>04</span>{t('termsAndConditions.04Title')}
          </h3>
          <p>
            {t('termsAndConditions.4.1Point')}
          </p>
          <p>
            {t('termsAndConditions.4.2Point')}
          </p>
          <p>
            {t('termsAndConditions.4.3Point')}
          </p>
          <p>
            {t('termsAndConditions.4.4Point')}
          </p>
          <p>{t('termsAndConditions.4.5Point')}</p>
          <p>
            {t('termsAndConditions.4.5.1Point')}
          </p>
          <p>{t('termsAndConditions.4.5.2Point')}</p>
          <p>
            {t('termsAndConditions.4.5.3Point')}
          </p>
          <p>
            {t('termsAndConditions.4.6Point')}
          </p>
          <p>
            {t('termsAndConditions.4.7Point')}
          </p>
          <h3 className={styles.h3}>
            <span>05</span>{t('termsAndConditions.05Title')}
          </h3>
          <p>
            {t('termsAndConditions.5.1Point')}
          </p>
          <p>
            {t('termsAndConditions.5.2Point')}
          </p>
          <h3 className={styles.h3}>
            <span>06</span>{t('termsAndConditions.06Title')}
          </h3>
          <p>
            {t('termsAndConditions.6.1Point')}
          </p>
          <h3 className={styles.h3}>
            <span>07</span>{t('termsAndConditions.07Title')}
          </h3>
          <p>
            {t('termsAndConditions.7.1Point')}
          </p>
          <p>
            {t('termsAndConditions.7.2Point')}
          </p>
          <p>
            {t('termsAndConditions.7.2.1Point')}
          </p>
          <p>
            {t('termsAndConditions.7.2.2Point')}
          </p>
          <p>
            {t('termsAndConditions.7.2.3Point')}
          </p>
          <p>
            {t('termsAndConditions.7.2.4Point')}
          </p>
          <p>
            {t('termsAndConditions.7.3Point')}
          </p>
          <p>
            {t('termsAndConditions.7.4Point')}
          </p>
          <h3 className={styles.h3}>
            <span>08</span>{t('termsAndConditions.08Title')}
          </h3>
          <p>
            {t('termsAndConditions.8.1Point')}
          </p>
          <p>{t('termsAndConditions.8.2Point')}</p>
          <p>{t('termsAndConditions.8.2.1Point')}</p>
          <p>
            {t('termsAndConditions.8.2.2Point')}
          </p>
          <p>{t('termsAndConditions.8.3Point')}</p>
          <p>
            {t('termsAndConditions.8.3.1Point')}
          </p>
          <p>{t('termsAndConditions.8.3.2Point')}</p>
          <p>
            {t('termsAndConditions.8.3.3Point')}
          </p>
          <p>{t('termsAndConditions.8.3.4Point')}</p>
          <h3 className={styles.h3}>
            <span>09</span>{t('termsAndConditions.09Title')}
          </h3>
          <p>
            {t('termsAndConditions.9.1Point')}
          </p>
          <h3 className={styles.h3}>
            <span>10</span>{t('termsAndConditions.10Title')}
          </h3>
          <p>
            {t('termsAndConditions.10.1Point')}
          </p>
          <h3 className={styles.h3}>
            <span>11</span>{t('termsAndConditions.11Title')}
          </h3>
          <p>
            {t('termsAndConditions.11.1Point')}
          </p>
          <p>
            {t('termsAndConditions.11.2Point')}
          </p>
          <p>
            {t('termsAndConditions.11.3Point')}
          </p>
          <p>
            {t('termsAndConditions.11.4Point')}
          </p>
          <p>
            {t('termsAndConditions.11.5Point')}
          </p>
          <h3 className={styles.h3}>
            <span>12</span>{t('termsAndConditions.12Title')}
          </h3>
          <p>
            {t('termsAndConditions.12.1Point')}
          </p>
          <h3 className={styles.h3}>
            <span>13</span>{t('termsAndConditions.13Title')}
          </h3>
          <div className={styles.contactMail}>

              {t('termsAndConditions.13.1Point')}

            <a href="mailto:contacts@cherishdev.com">
              contacts@cherishdev.com
            </a>
          </div>
        </div>
        <DocFooter />
      </div>
    </main>
  );
};

export default TermsAndConditions;
