import { Link, useLocation } from "react-router-dom";
import styles from "./styles/menu.module.sass";
import { linksData } from "constants/social";
import { useTranslation } from "react-i18next";

const Menu = ({ active, setActive }) => {
  const { t, i18n } = useTranslation();

  const router = useLocation();

  const menuServicesData = t('menu', { returnObjects: true });

  const menuNavData = t('menuNavData', { returnObjects: true });

  const navData = i18n.resolvedLanguage === "ru" ? "navDataRu" : "navData";
  return (
    <div
      className={
        active ? `${styles.menuActive} ${styles.menuBox}` : styles.menuBox
      }
    >
      <div className={styles.menu}>
        <div className={styles.menuServices}>
          <h5 className={styles.h5}>{t('footer.services')}</h5>
          <ul>
            {menuServicesData?.map((data) => {
              return (
                <li key={data.href} onClick={() => setActive(false)}>
                  <Link
                    style={
                      data.href === router.pathname
                        ? { color: "#179EFF" }
                        : null
                    }
                    to={data.href}
                  >
                    {data.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <button className={styles.button} onClick={() => setActive(false)}>
          <svg
            viewBox="0 0 58 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              transform="matrix(0.707107 -0.707107 0.707083 0.707131 0 1.99805)"
              fill="#687380"
            />
            <rect
              transform="matrix(0.707107 0.707107 -0.707083 0.707131 56.5771 1.00781)"
              fill="#687380"
            />
          </svg>
        </button>
      </div>
      <div className={styles[navData]}>
        <ul>
          {menuNavData?.map((data) => {
            return (
              <li key={data.href} onClick={() => setActive(false)}>
                <Link
                  style={
                    data.href === router.pathname ? { color: "#179EFF" } : null
                  }
                  to={data.href}
                >
                  {data.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.menuContacts}>
        <div className={styles.menuMail}>
          <a href="mailto:contacts@cherishdev.com">contacts@cherishdev.com</a>
        </div>
        <div className={styles.menuLinks}>
          <ul className={styles.links}>
            {linksData.map((data) => {
              return (
                <li key={data.key}>
                  <a href={data.href} target="_blank" rel="noreferrer">
                    {data.link}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
