import styles from "./styles/task.module.sass";
import { useTranslation } from "react-i18next";

const TaskToPerform = ({
  problemsTitle,
  problemsText,
  remediesTitle,
  remediesData,
}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.tasksBox}>
      <div className={styles.tasksTitleBox}>
        <h2 className={styles.tasksTitle}>
          {t('cases.taskToPerform.title')} <span>{t('cases.taskToPerform.subTitle')}</span>
        </h2>
      </div>
      <div className={styles.tasksContentBox}>
        <div className={styles.tasksContent}>
          <div className={styles.tasksProblems}>
            {problemsTitle ? (
              <h3 className={styles.problemsTitle}>{t('cases.taskToPerform.problemsTitle')}</h3>
            ) : null}
            {problemsText ? (
              <p className={styles.problemsText}>{problemsText}</p>
            ) : null}
          </div>
          <div className={styles.tasksRemedies}>
            {remediesTitle ? (
              <h3 className={styles.remediesTitle}>{t('cases.taskToPerform.remediesTitle')}</h3>
            ) : null}
            <ul className={styles.tasksRemediesList}>
              {remediesData?.map((data) => {
                return (
                  <li key={data.number}>
                    {data.number}
                    <br />
                    <span>{data.text}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskToPerform;
