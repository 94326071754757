const EMAIL_ADDRESS_REGEXP = /(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/;

// validation functions return true if value is valid and Error mesage if not
export const required = (value) => {
    return !!value.trim() || "Field is required";
};

export const email = (value) => {
    return EMAIL_ADDRESS_REGEXP.test(value) || "Invalid e-mail";
};

// validation function
export const validate = (rules, value) => {
    const errors = [];
    rules && Object.values(rules).forEach(rule => {
        const valid = rule(value);
        valid !== true && errors.push(valid);
    });

    return errors;
};
