import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import blog from "images/icons/nav/blog.svg";
import blogActive from "images/icons/nav/blog-active.svg";
import cases from "images/icons/nav/cases.svg";
import casesActive from "images/icons/nav/cases-active.svg";
import home from "images/icons/nav/home.svg";
import homeActive from "images/icons/nav/home-active.svg";
import services from "images/icons/nav/services.svg";
import servicesActive from "images/icons/nav/services-active.svg";
import dots from "images/icons/nav/dots.svg";
import dotsActive from "images/icons/nav/dots-active.svg";

import style from "./mobileNav.module.sass";
import { useTranslation } from "react-i18next";

const icons = {
  blog, 
  blogActive,
  cases,
  casesActive,
  home,
  homeActive,
  services,
  servicesActive,
  dots,
  dotsActive
}

const MobileNav = () => {
  const {t} = useTranslation();
  const mobileNavItems = t('mobileNavItems', { returnObjects: true });
  
  const [selectedItemKey, setSelectedItemKey] = useState(null);
  const [isSubMenuOpened, setIsSubMenuOpened] = useState(false);
  
  const router = useHistory();
  const routerLocation = useLocation();

  const onItemClick = (key, to, showSubMenu = false) => {
    if(to) {
      router.push(to);
    }
    setSelectedItemKey(key);
    setIsSubMenuOpened(showSubMenu)
  };

  useEffect(() => {
    if(!selectedItemKey) {
      Object.keys(mobileNavItems).forEach((outerItemKey) => {
        if(mobileNavItems[outerItemKey].to) {
          routerLocation.pathname === mobileNavItems[outerItemKey].to && setSelectedItemKey(outerItemKey);
        } else if (mobileNavItems[outerItemKey].children) {
          mobileNavItems[outerItemKey].children.forEach((innerItem) => {
            innerItem.to && routerLocation.pathname === innerItem.to && setSelectedItemKey(outerItemKey);
          });
        }
      })
    };
  }, [mobileNavItems, routerLocation.pathname, selectedItemKey]);

  return (
    <nav className={style.mobileNav}>
      {Object.keys(mobileNavItems).map((navKey) => (
        <div key={navKey}>
          <button
            className={navKey === selectedItemKey ? style.selected : ""}
            onClick={() => onItemClick(navKey, mobileNavItems[navKey].to, !mobileNavItems[navKey].to)}>
            <img
              src={navKey === selectedItemKey ? icons[mobileNavItems[navKey].activeIcon] : icons[mobileNavItems[navKey].icon]}
              alt={mobileNavItems[navKey].name}/>
            <span>{mobileNavItems[navKey].name}</span>
          </button>
          {selectedItemKey === navKey && isSubMenuOpened && (
            <ul>
              {mobileNavItems[navKey].children.map(innerItem => (
                <button
                  key={innerItem.name}
                  className={routerLocation.pathname === innerItem.to ? style.selected : ""}
                  onClick={() => onItemClick( navKey, innerItem.to)}>
                  {innerItem.name}
                </button>
              ))}
            </ul>
          )}
        </div>
      ))}
    </nav>
  );
};

export default MobileNav;
