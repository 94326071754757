import useMedia from "../../../hooks/useMedia";
import { useState, useEffect } from "react";
import tools from "../../../images/services/Tools.svg";
import appiumIcon from "../../../images/services/toolsIcons/appium.svg";
import dockerIcon from "../../../images/services/toolsIcons/docker.svg";
import allureIcon from "../../../images/services/toolsIcons/allure.svg";
import cucumberIcon from "../../../images/services/toolsIcons/cucumber.svg";
import cypressIcon from "../../../images/services/toolsIcons/cypress.svg";
import jenkinsIcon from "../../../images/services/toolsIcons/jenkins.svg";
import jiraIcon from "../../../images/services/toolsIcons/jira.svg";
import playwrightIcon from "../../../images/services/toolsIcons/playwright.svg";
import seleniumIcon from "../../../images/services/toolsIcons/selenium.svg";
import selenoidIcon from "../../../images/services/toolsIcons/selenoid.svg";
import sorrycypressIcon from "../../../images/services/toolsIcons/sorrycypress.svg";
import teamcityIcon from "../../../images/services/toolsIcons/teamcity.svg";
import webdriverIcon from "../../../images/services/toolsIcons/webdriver.svg";
import styles from "./servicesAutomated.module.sass";
import { useTranslation } from "react-i18next";

const toolsData = [
  { title: "Appium", icon: appiumIcon },
  { title: "Docker", icon: dockerIcon },
  { title: "Webdriver", icon: webdriverIcon },
  { title: "TeamCity", icon: teamcityIcon },
  { title: "Selenium", icon: seleniumIcon },
  { title: "Sorry Cypress", icon: sorrycypressIcon },
  { title: "Jira", icon: jiraIcon },
  { title: "Jenkins", icon: jenkinsIcon },
  { title: "Cypress", icon: cypressIcon },
  { title: "Cucumber", icon: cucumberIcon },
  { title: "Selenoid", icon: selenoidIcon },
  { title: "Playwright", icon: playwrightIcon },
  { title: "Allure", icon: allureIcon },
];

const ServicesAutomated = () => {
  const { t, i18n } = useTranslation();
  const h6 = i18n.resolvedLanguage === 'ru' ? 'h6Ru' : 'h6';

  const { isDesktop, isTablet } = useMedia();
  const [toolsLine, setToolsLine] = useState([]);

  useEffect(() => {
    if (isDesktop) {
      setToolsLine([
        toolsData.slice(0, 6),
        toolsData.slice(6, toolsData.length),
      ]);
    } else if (isTablet) {
      setToolsLine([
        toolsData.slice(0, 4),
        toolsData.slice(4, 9),
        toolsData.slice(9, toolsData.length),
      ]);
    } else {
      setToolsLine([
        toolsData.slice(0, 3),
        toolsData.slice(3, 6),
        toolsData.slice(6, 10),
        toolsData.slice(10, toolsData.length),
      ]);
    }
  }, [isDesktop, isTablet]);

  return (
    <div className={styles.automatedBox}>
      <div className={styles.automatedToolsTitle}>
        <h6 className={styles[h6]}>
          {t('servicesPage.servicesAutomated.title')}
          <br />
          <span>{t('servicesPage.servicesAutomated.subTitle')}</span>
        </h6>
        <h2 className={styles.h2}>{t('servicesPage.servicesAutomated.tools')}</h2>
      </div>
      <div className={styles.automatedToolsContent}>
        {toolsLine.map((line, index) => {
          return (
            <ul key={index}>
              {line.map(({ title, icon }, index) => {
                return (
                  <li key={index}>
                    {title}
                    <img src={icon} alt="toolIcon" />
                  </li>
                );
              })}
            </ul>
          );
        })}
      </div>
      <div className={styles.automatedLanguages}>
        <h2 className={styles.h2}>{t('servicesPage.servicesAutomated.languages')}</h2>
        <div className={styles.automatedToolsImg}>
          <img src={tools} alt="tools" />
        </div>
      </div>
    </div>
  );
};

export default ServicesAutomated;
