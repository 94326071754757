import { useHistory, useParams } from "react-router-dom";

import styles from "./categories.module.sass";

function CategoriesData({ categories }) {
  const router = useHistory();
  const { categorySlug } = useParams();

  const chooseCategory = (slug) => {
    router.push(slug ? `/blog/category/${slug}` : '/blog')
  };

  return (
    <div className={styles.categories}>
      <button
        className={(categorySlug
          ? [styles.categoriesButton]
          : [styles.categoriesButton, styles.choosenCategory]
        ).join(" ")}
        onClick={() => {
          chooseCategory(null);
        }}
      >
        All articles
      </button>
      {categories.map(({ name, slug }) => {
        return (
          <button
            key={slug}
            className={(categorySlug === slug
              ? [styles.categoriesButton, styles.choosenCategory]
              : [styles.categoriesButton]
            ).join(" ")}
            onClick={() => {
              chooseCategory(slug);
            }}
          >
            {name}
          </button>
        );
      })}
    </div>
  );
}

export default CategoriesData;
