import { useHistory, Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../images/bigArrow.svg";
import styles from "./styles/button.module.sass";

const BackButton = ({ title, href }) => {
  const history = useHistory();
  if (href) {
    return (
      <Link to={href} className={styles.backBtn}>
        <Arrow className={styles.arrow} /> {title}
      </Link>
    );
  }
  return (
    <button className={styles.backBtn} onClick={history.goBack}>
      <Arrow className={styles.arrow} />
      {title}
    </button>
  );
};

export default BackButton;
