import { Link } from "react-router-dom";
import styles from "./servicesArrow.module.sass";

const ServicesPrevArrow = ({
  aboveArrText,
  underArrText,
  link,
  arrowSrc,
  imgSrc,
}) => {
  return (
    <div className={styles.prevArrowBox}>
      <div className={styles.prevImg}>
        {imgSrc ? <img src={imgSrc} alt="arrow" /> : null}
      </div>
      <div className={styles.prevArrowLink}>
        <Link to={link}>
          <p className={styles.aboveText}>
            {aboveArrText ? aboveArrText : null}
          </p>
          <img src={arrowSrc} alt="arrow" />
          <p className={styles.underText}>
            {underArrText ? underArrText : null}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ServicesPrevArrow;
