import { useState } from "react";
import { Link } from "react-router-dom";
import { sendContactForm } from "services/forms";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";

import { ThanksForContact } from "components/modals/Modals";
import TextField from "components/UI/formControls/TextField";
import PrimaryButton from "components/UI/button/PrimaryButton";
import FileField from "components/UI/formControls/FileField";
import Checkbox from "components/UI/formControls/Checkbox";

import { required, email as emailRule } from "utils/validationRules";
import useValidateForm from "hooks/useValidateForm";
import useCapthca from "hooks/useCaptcha";

import styles from "./contactsForm.module.sass";
import { openErrorModal } from "utils/errors";
import { useTranslation } from "react-i18next";

const formName = "contactsForm";

const ContactsForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [token, updateToken] = useCapthca(formName);

  const openThanksForContact = () => {
    dispatch(openModal(ThanksForContact));
  };

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [file, setFile] = useState(null);
  const [isPrivacyChecked, setPrivacyChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = useValidateForm(formName);

  let errors = {
    name: false,
    company: false,
    email: false,
    comment: false,
    file: false,
    isPrivacyChecked: false,
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("company", company);
    formData.append("email", email);
    formData.append("comment", comment);
    formData.append("isPrivacyChecked", isPrivacyChecked);
    formData.append("captchaToken", token);

    setIsLoading(true);

    sendContactForm(formData)
      .then(() => {
        openThanksForContact();
        updateToken();
      })
      .catch( (err) => {
        openErrorModal(err, dispatch);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPressSend = (e) => {
    e.preventDefault();
    validateForm(); // trigger form validation
    if (Object.values(errors).every((e) => e === false)) {
      // check that all field has no errors
      sendForm();
    } else {
      console.log("Form is not valid");
    }
  };

  return (
    <form name={formName} className={styles.contactsForm}>
      <TextField
        placeholder={t('modals.components.textFieldName')}
        value={name}
        onChange={(value, error) => {
          setName(value);
          errors = { ...errors, name: error };
        }}
      />
      <TextField
        placeholder={t('modals.components.textFieldCompany')}
        value={company}
        onChange={(value, error) => {
          setCompany(value);
          errors = { ...errors, company: error };
        }}
      />
      <TextField
        placeholder="my_email@gmail.com"
        value={email}
        rules={{ required, email: emailRule }}
        onChange={(value, error) => {
          errors = { ...errors, email: error };
          setEmail(value);
        }}
      />
      <div className={styles.contactsFormGroup}>
        <div className={styles.textarea}>
          <TextField
            textarea
            placeholder={t('modals.components.textFieldProject')}
            value={comment}
            onChange={(value, error) => {
              setComment(value);
              errors = { ...errors, comment: error };
            }}
          />
        </div>
        <div className={styles.button}>
          <PrimaryButton
            text={t('modals.components.primaryButton')}
            onClick={onPressSend}
            isLoading={isLoading}
            disabled={!isPrivacyChecked}
          />
        </div>
      </div>
      <FileField
        onSelect={setFile}
        extensions="image/*,audio/*,video/*,.docx,.doc,.pdf"
        name="contactsFile"
        text={t('modals.components.fileField')}
      />
      <Checkbox
        name="privacy"
        value={isPrivacyChecked}
        onChange={() => setPrivacyChecked(!isPrivacyChecked)}
      >
        <p>
          {t('footer.sendingText')}
          <Link to="/privacy-policy">{t('footer.privacyLink')}</Link>
        </p>
      </Checkbox>
    </form>
  );
};

export default ContactsForm;
