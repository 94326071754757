import useMedia from "../../../hooks/useMedia";
import PageTitle from "../../UI/pageTitle/PageTitle";
import styles from "../styles/benefits.module.sass";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const Benefits = () => {
  const { t } = useTranslation();

  const { isMobile } = useMedia();
  return (
    <div className={styles.benefitsBox}>
      <div className={styles.benefitsTitle}>
        <PageTitle title={t('main.benefits.title')} subTitle={t('main.benefits.subTitle')} />
      </div>
      {!isMobile ? (
        <div className={styles.benefits}>
          <p>
            {t('main.benefits.frSlide')}
          </p>
          <p>
            {t('main.benefits.scSlide')}
          </p>
          <p>
            {t('main.benefits.thSlide')}
          </p>
          <p>
            {t('main.benefits.fthSlide')}
          </p>
          <p>{t('main.benefits.fifSlide')}</p>
        </div>
      ) : (
        <div className={styles.benefitsSlider}>
          <div className={styles.benefitsContainer}>
            <Swiper
              speed={1000}
              parallax={true}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                clickable: true,
                bulletActiveClass: styles.processPaginationActive,
              }}
              slidesPerView={1}
              spaceBetween={0}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              autoplay={{
                waitForTransition: true,
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Keyboard, Parallax]}
              className={styles.swiper}
            >
              <div
                slot="container-start"
                className={styles.parallaxBg}
                data-swiper-parallax="-75%"
              ></div>
              <SwiperSlide className={styles.slide}>
                <p>
                  {t('main.benefits.frSlide')}
                </p>
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <p>
                  {t('main.benefits.scSlide')}
                </p>
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <p>
                  {t('main.benefits.thSlide')}
                </p>
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <p className={styles.periodText}>
                  {t('main.benefits.fthSlide')}
                </p>
              </SwiperSlide>
              <SwiperSlide className={styles.slide}>
                <p className={styles.stackText}>{t('main.benefits.fifSlide')}</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default Benefits;
