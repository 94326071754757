import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../services/posts";


export const fetchCategories = createAsyncThunk("categories/fetchCategories", async () => {
  return getCategories().then((response) => response.data).catch((err) => err);
});

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    loadingCategories: false,
  },

  extraReducers: {
    [fetchCategories.pending]: (state) => {
      state.loadingCategories = true;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.loadingCategories = false;
      state.categories = action.payload;
    },
    [fetchCategories.rejected]: (state) => {
      state.loadingCategories = false;
    },
  },
});

export default categoriesSlice.reducer;
