import styles from "./styles/pageTitle.module.sass";

const PageTitle = ({ title, subTitle }) => {
  return (
    <h6 className={styles.mainPrimaryTitle}>
      {title}
      <br />
      <span className={styles.mainSubTitle}>{subTitle}</span>
    </h6>
  );
};

export default PageTitle;
