import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { openModal } from "features/modal-slice";
import PrimaryButton from "../../UI/button/PrimaryButton";
import Slogan from "../../UI/slogan/Slogan";
import MainServicesLinks from "./MainServicesLists";
import { ContactUsModal } from "components/modals/Modals";

import ManualLogo from "../../../images/imgs/ManualLogo.svg";
import AutomationLogo from "../../../images/imgs/AutomationLogo.svg";
import ConsultingLogo from "../../../images/imgs/ConsultingLogo.svg";
import MainLogo from "../../../images/imgs/MainLogo.svg";
import SubscriptionLogo from "../../../images/imgs/SubscriptionLogo.svg";

import styles from "../styles/main.module.sass";

import { useTranslation } from "react-i18next";

const Main = () => {
  const { t, i18n } = useTranslation();

  const mainTextClass = i18n.resolvedLanguage === 'ru' ? 'mainTextRu' : 'mainText'
  const subscriptionCard = i18n.resolvedLanguage === 'ru' ? 'subscriptionCardRu' : 'subscriptionCard'

  const dispatch = useDispatch();

  const [isMain, setMain] = useState(true);
  const [isManual, setIsManual] = useState(false);
  const [isAutomation, setIsAutomation] = useState(false);
  const [isConsulting, setIsConsulting] = useState(false);
  const [isSubscription, setSubscription] = useState(false);

  const backImg = isManual
    ? ManualLogo
    : isAutomation
    ? AutomationLogo
    : isConsulting
    ? ConsultingLogo
    : isSubscription
    ? SubscriptionLogo
    : null;

  const backImgStyle = !isMain ? styles.backImg : styles.hiddenBackImg;

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.mainBox}>
        <div className={isMain ? styles.mainSlogan : styles.mainSloganHidden}>
          <Slogan />
        </div>
        {backImg ? (
          <div className={backImgStyle}>
            <img alt="logo" src={backImg} loading="eager" />
          </div>
        ) : (
          <div className={backImgStyle}>
            <img alt="logo" src={MainLogo} loading="eager" />
          </div>
        )}
        <div className={styles.mainLinks}>
          <div className={styles.linksContent}>
            <div className={styles.changebleText}>
              <p className={isManual ? styles[mainTextClass] : styles.hiddenMainText}>
                {t('main.manualText')}
              </p>
              <p
                className={
                  isAutomation ? styles[mainTextClass] : styles.hiddenMainText
                }
              >
               {t('main.automationText')}
              </p>
              <p
                className={
                  isConsulting ? styles[mainTextClass] : styles.hiddenMainText
                }
              >
                {t('main.consultingText')}
              </p>
              <p
                className={
                  isSubscription ? styles[mainTextClass] : styles.hiddenMainText
                }
              >
                {t('main.subscriptionText')}
              </p>
            </div>
            <div className={styles.linksTitles}>
              <h2
                onMouseEnter={() => {
                  setIsManual(true);
                  setMain(false);
                }}
                onMouseLeave={() => {
                  setIsManual(false);
                  setMain(true);
                }}
              >
                <Link to="/services/manual-testing">{t('services.manual')}</Link>
              </h2>
              <h2
                onMouseEnter={() => {
                  setIsAutomation(true);
                  setMain(false);
                }}
                onMouseLeave={() => {
                  setIsAutomation(false);
                  setMain(true);
                }}
              >
                <Link to="/services/test-automation">{t('services.automation')}</Link>
              </h2>
              <h2
                onMouseEnter={() => {
                  setIsConsulting(true);
                  setMain(false);
                }}
                onMouseLeave={() => {
                  setIsConsulting(false);
                  setMain(true);
                }}
              >
                <Link to="/services/consulting&audit">{t('services.consulting')}</Link>
              </h2>
              <h2
                onMouseEnter={() => {
                  setSubscription(true);
                  setMain(false);
                }}
                onMouseLeave={() => {
                  setSubscription(false);
                  setMain(true);
                }}
              >
                <Link to="/services/qa-subscription">{t('services.subscription')}</Link>
              </h2>
              <Link to="/services/qa-subscription" className={styles[subscriptionCard]}>
                <p className={styles.text}>{t('main.subscriptionCard.text')}</p>
                <h2 className={styles.title}>{t('main.subscriptionCard.title')}</h2>
                <h3 className={styles.subTitle}>{t('main.subscriptionCard.subTitle')}</h3>
              </Link>
            </div>
            <MainServicesLinks
              isManual={isManual}
              isAutomation={isAutomation}
              isConsulting={isConsulting}
              isSubscription={isSubscription}
            />
          </div>
          <div className={styles.btnBox}>
            <div>
              <PrimaryButton
                className={styles.mybtn}
                text={t('primaryButton')}
                onClick={openContactUsModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
