import { Link } from "react-router-dom";
import { useState } from "react";
import useMedia from "../../hooks/useMedia";
import styles from "./styles/casePreview.module.sass";
import { useTranslation } from "react-i18next";

const Case = ({
  title,
  industry,
  duration,
  staff,
  engagement,
  platforms,
  href,
  img,
  hoverImg,
}) => {
  const { t } = useTranslation();
  const [mouseOn, setMouseOn] = useState(false);
  const { isMobile } = useMedia();
  return (
    <div className={styles.caseBox}>
      <div className={styles.caseMainBox}>
        <div className={styles.caseContent}>
          {!isMobile ? (
            <h4
              className={
                mouseOn ? `${styles.caseTitleHover}` : `${styles.caseTitle}`
              }
              onMouseEnter={() => setMouseOn(true)}
              onMouseLeave={() => setMouseOn(false)}
            >
              <Link to={href}>
                {title}
                <svg
                  viewBox="0 0 80 81"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
                    fill="#4EB5FF"
                  />
                </svg>
              </Link>
            </h4>
          ) : null}
          {!isMobile ? (
            <div className={styles.caseList}>
              <ul className={styles.caseListFirst}>
                <li>
                  <span>{t('cases.case.frPoint')}</span>
                  <br />
                  {industry}
                </li>
                <li>
                  <span>{t('cases.case.scPoint')}</span>
                  <br />
                  {duration}
                </li>
                <li>
                  <span>{t('cases.case.thPoint')}</span>
                  <br />
                  {staff}
                </li>
              </ul>
              <ul className={styles.caseListSecond}>
                <li>
                  <span>{t('cases.case.fthPoint')}</span>
                  <br />
                  {engagement}
                </li>
                <li>
                  <span>{t('cases.case.fifPoint')}</span>
                  <br />
                  {platforms}
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        <div
          className={styles.caseImg}
          onMouseEnter={() => setMouseOn(true)}
          onMouseLeave={() => setMouseOn(false)}
        >
          {mouseOn ? (
            <Link to={href}>
              <img alt="case" src={hoverImg} loading="eager" />
            </Link>
          ) : (
            <Link to={href}>
              <img alt="case" src={img} loading="eager" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Case;
