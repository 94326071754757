import { Link, useLocation } from "react-router-dom";
import styles from "./getTrial.module.sass";
import { useTranslation } from "react-i18next";

const GetTrial = () => {
  const { t } = useTranslation();
  const router = useLocation();
  const trialHref = "/trial";
  return (
    <div>
      <Link
        className={(trialHref === router.pathname
          ? [styles.getTrialBlock]
          : [styles.getTrial]
        ).join(" ")}
        to={trialHref}
      >
        {t('trialLink')}
      </Link>
    </div>
  );
};

export default GetTrial;
