import styles from "./servicesTitle.module.sass";

const ServicesTitle = ({ fTitle, sTitle, thTitle, ftTitle }) => {
  return (
    <div className={styles.servicesTitle}>
      <span>{fTitle}</span>
      <p>{sTitle}</p>
      <p>{thTitle}</p>
      <p>{ftTitle}</p>
    </div>
  );
};

export default ServicesTitle;
