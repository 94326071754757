import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTags } from "../services/posts";

export const fetchTags = createAsyncThunk("tags/fetchTags", async (query = {}) => {
  return getTags(query).then((response) => response.data).catch((err) => err);
});

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    tags: [],
    tagsObj: {},
    loadingTags: false,
  },

  extraReducers: {
    [fetchTags.pending]: (state) => {
      state.loadingTags = true;
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.loadingTags = false;
      state.tags = action.payload;
      const obj = {}
      const allTags = action.payload;
      allTags.forEach((t) => {
        obj[t.id] = t.name;
      });
      state.tagsObj = obj
    },
    [fetchTags.rejected]: (state) => {
      state.loadingTags = false;
    },
  },
});

export default tagsSlice.reducer;
