import { useClipboard } from "use-clipboard-copy";
import styles from "./styles/contacts.module.sass";
import { linksData } from "constants/social";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();

  const clipboardFirst = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });
  const clipboardSecond = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });

  return (
    <div className={styles.contactsModal}>
      <div id={styles.contactsPhones}>
        <p
          ref={clipboardFirst.target}
          onClick={() => {
            clipboardFirst.copy(clipboardFirst.target.current.innerText);
          }}
        >
          <span>{t('footer.basicCountry')}</span>
            {t('footer.basicPhone')}
        </p>
        <p
          ref={clipboardSecond.target}
          onClick={() => {
            clipboardSecond.copy(clipboardSecond.target.current.innerText);
          }}
        >
          <span>{t('footer.additionalCountry')}</span>
            {t('footer.additionalPhone')}
        </p>
      </div>
      <ul className={styles.links}>
        {linksData.map((data) => {
          return (
            <li key={data.key}>
              <a href={data.href} target="_blank" rel="noreferrer">
                {data.link}
              </a>
            </li>
          );
        })}
      </ul>
      <div className={styles.mail}>
        <a href="mailto:contacts@cherishdev.com">contacts@cherishdev.com</a>
      </div>
      <p id={styles.contactsAdress}>{t('footer.address')}</p>
    </div>
  );
};

export default Contacts;
