import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";
import { FollowModal } from "components/modals/Modals";
import { ReactComponent as ArrowWhite } from "../../images/icons/bigArrowWhite.svg";
import styles from "./follow.module.sass";
import { useTranslation } from "react-i18next";

const Follow = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const follow = i18n.resolvedLanguage === 'ru'? "followRu" : "follow"

  const openFollowModal = () => {
    dispatch(openModal(FollowModal));
  };
  return (
    <div className={styles[follow]}>
      <div className={styles.followForm}>
        <h2 className={styles.h2} onClick={openFollowModal}>
          {t('blog.follow.title')}
          <ArrowWhite className={styles.arrow} />
          <span>{t('blog.follow.subTitle')}</span>
        </h2>
      </div>
      <p>{t('blog.follow.text')}</p>
    </div>
  );
};

export default Follow;
