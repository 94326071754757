import styles from "./styles/doc.module.sass";
import { useTranslation } from "react-i18next";

const DocText = () => {

  const { t } = useTranslation();
  
  return (
    <div className={styles.docBox}>
      <p>
          {t('docText.order')}
      </p>
      <p>
          {t('docText.sending')}
      </p>
      <p>
          {t('docText.document')}
      </p>
      <h1 className={styles.h1}>{t('docText.owner')}</h1>
      <p>{t('docText.address')}</p>
      <p>
        {t('docText.contact')}
        <a className={styles.docLink} href="mailto:contacts@cherishdev.com">
            contacts@cherishdev.com
        </a>
      </p>
    </div>
  );
};

export default DocText;
