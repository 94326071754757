import { useState } from "react";
import useMedia from "../../hooks/useMedia";
import { Link } from "react-router-dom";

import Menu from "../Menu/Menu";
import arrow from "../../images/arrow.svg";

import styles from "./nav.module.sass";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const {t} = useTranslation();
  const navItems = t('navItems', { returnObjects: true });

  const { isDesktop } = useMedia();

  const [activeItem, setActiveItem] = useState(null);
  const [isActive, setActive] = useState(false);
  
  const isItemActive = (item) => 
    activeItem && activeItem.href === item.href;
  const activateItem = (item) =>
    setActiveItem(isItemActive(item) ? null : item);

  return (
    <nav className={styles.nav}>
      <button className={styles.navButton} onClick={() => setActive(true)}>
          {t('nav.title')}
      </button>
      {isDesktop ? (
        <ul className={styles.navMenuList}>
          {Object.keys(navItems).map((outerItemKey) => (
            <div key={outerItemKey}>
              <li onClick={() => activateItem(navItems[outerItemKey])}>
                {!navItems[outerItemKey].children ? (
                  <Link to={navItems[outerItemKey].href}>{navItems[outerItemKey].name}</Link>
                ) : (
                  <>
                    <span>{navItems["services"].name}</span>
                    <img
                      className={styles.arrow}
                      src={arrow}
                      alt="arrow"
                      style={{
                        transform: isItemActive(navItems[outerItemKey])
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                    />
                  </>
                )}
              </li>
              {navItems[outerItemKey].children && isItemActive(navItems[outerItemKey]) && (
                <ul className={styles.navSubMenuList}>
                  {navItems[outerItemKey].children.map((innerItem) => (
                    <li key={innerItem.href}>
                      <Link to={innerItem.href}>{innerItem.name}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </ul>
      ) : null}
      <Menu active={isActive} setActive={setActive} />
    </nav>
  );
};

export default Nav;
