import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";

import Card from "../../components/UI/card/Card";
import { CooperateModal } from "components/modals/Modals";

import cardsCompany from "../../images/imgs/cardsCompany.svg";
import mountains from "../../images/imgs/mountains.png";
import vision from "../../images/imgs/Vision.png";
import values from "../../images/imgs/Values.png";
import Victor from "../../images/imgs/Victor.svg";
import Alex from "../../images/imgs/Alex.svg";
import bigArrow from "../../images/icons/bigArrowBlack.svg";
import styles from "./styles/company.module.sass";
import { useTranslation } from "react-i18next";

const Company = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const boxLets = i18n.resolvedLanguage === 'ru' ? 'boxLetsRu' : 'boxLets'
  const valuesListSecond = i18n.resolvedLanguage === 'ru' ? 'valuesListSecondRu' : 'valuesListSecond'
  const cardsText = i18n.resolvedLanguage === 'ru' ? 'cardsTextRu' : 'cardsText'

  const openCooperateModal = () => {
    dispatch(openModal(CooperateModal));
  };
  return (
    <div className={styles.company}>
      <div className={styles.companyHeader}>
        <h1 className={styles.h1}>
          Cherish DEV <br /> <span>{t('company.companyHeader.title')}</span>
        </h1>
        <img src={cardsCompany} loading="eager" alt="company" />
        <div className={styles.cardsCompany}>
          <div className={styles[cardsText]}>
            <h2 className={styles.cardsTitle}>{t('company.companyHeader.frCardTitle')}</h2>
            <p>
              {t('company.companyHeader.frCard')}
            </p>
          </div>
          <div className={styles[cardsText]}>
            <h2 className={styles.cardsTitle}>{t('company.companyHeader.scCardTitle')}</h2>
            <p>
              {t('company.companyHeader.scCard')}
            </p>
          </div>
        </div>
        <p>{t('company.companyHeader.text')}</p>
      </div>
      <div className={styles.boxMission}>
        <h2 className={styles.h2}>
          {t('company.boxMission.title')} <br />
          <span>{t('company.boxMission.subTitle')}</span>
        </h2>
        <div className={styles.textMission}>
          <img
            src={mountains}
            placeholder="blur"
            alt="mountains"
            loading="eager"
          />
          <p>
            {t('company.boxMission.text')}
          </p>
        </div>
      </div>
      <div className={styles.boxVision}>
        <h2 className={styles.h2}>
          {t('company.boxVision.title')} <br />
          <span>{t('company.boxVision.subTitle')}</span>
        </h2>
        <img src={vision} alt="vision" loading="eager" />
        <p>
          {t('company.boxVision.text')}
        </p>
      </div>
      <div className={styles.boxValues}>
        <h2 className={styles.h2}>
          {t('company.boxValues.title')} <br />
          <span>{t('company.boxValues.subTitle')}</span>
        </h2>
        <img src={values} alt="values" loading="eager" />
        <ul className={styles.valuesListFirst}>
          <li>{t('company.boxValues.fstLi')}</li>
          <li>{t('company.boxValues.scdLi')}</li>
          <li>{t('company.boxValues.thdLi')}</li>
        </ul>
        <ul className={styles[valuesListSecond]}>
          <li>{t('company.boxValues.frthLi')}</li>
          <li>{t('company.boxValues.ffthLI')}</li>
          <li>{t('company.boxValues.sxthLi')}</li>
        </ul>
      </div>
      <div className={styles.boxLeadership}>
        <h2 className={styles.h2}>
          <span>{t('company.boxLeadership.title')}</span>
        </h2>
        <div className={styles.boxPhoto}>
          <div className={styles.photo1}>
            <img src={Alex} alt="Alex" loading="eager" />
            <h3 className={styles.h3}>{t('company.boxLeadership.photo1.name')}</h3>
            <p>{t('company.boxLeadership.photo1.position')}</p>
            <div className={styles.card}>
              <Card
                text={t('company.boxLeadership.photo1.cardText')}
              />
            </div>
            <ul className={styles.linksPhoto1}>
              <li>
                <a
                  href="https://www.linkedin.com/in/alexprotasov/"
                  target="_blank"
                  rel="noreferrer"
                >
                  linkin
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.photo2}>
            <img src={Victor} alt="Victor" loading="eager" />
            <h3 className={styles.h3}>{t('company.boxLeadership.photo2.name')}</h3>
            <p>{t('company.boxLeadership.photo2.position')}</p>
            <div className={styles.card}>
              <Card
                text={t('company.boxLeadership.photo2.cardText')}
              />
            </div>
            <ul className={styles.linksPhoto2}>
              <li>
                <a
                  href="https://www.linkedin.com/in/victor-malyavko/"
                  target="_blank"
                  rel="noreferrer"
                >
                  linkin
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles[boxLets]}>
        <p>
          {t('company.boxLets.text')}
        </p>
        <button className={styles.companyBtn} onClick={openCooperateModal}>
          {t('company.boxLets.companyBtn')}
          <img src={bigArrow} alt="bigArrow" loading="eager" />
        </button>
      </div>
    </div>
  );
};
export default Company;
