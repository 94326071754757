import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect, useState } from "react";

export default function useCapthca (formName) {
  const [token, setToken] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const updateToken = useCallback(() => {
    executeRecaptcha && executeRecaptcha(formName)
      .then(_token => setToken(_token))
      .catch(error => console.error(error));
  }, [formName, executeRecaptcha]);

  useEffect(() => {
    if(!token) updateToken();
  }, [token, updateToken])

  return [
    token,
    updateToken
  ];
};
