import { useState, useRef } from "react";
import icon from "../../../images/icons/vectorForm.svg";
import { ReactComponent as ResetFileIcon } from "../../../images/icons/resetFile.svg";
import styles from "./formControls.module.sass";

const FileField = ({
  name,
  text,
  extensions = "image/*,audio/*,video/*,.docx,.doc,.pdf",
  onSelect,
}) => {
  const [fileName, setFileName] = useState(null);

  const inputRef = useRef(null);

  const onChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : null);
    onSelect(file ? file : null);
  };

  const onReset = (e) => {
    e.preventDefault();
    inputRef.current.value = null;
    setFileName(null);
  };

  return (
    <label htmlFor={name} className={styles.file}>
      <div>
        <img src={icon} alt="icon" loading="eager" />
        <span>{text}</span>
      </div>
      <input
        type="file"
        id={name}
        name={name}
        accept={extensions}
        onChange={onChange}
        ref={inputRef}
      />
      {fileName ? (
        <div className={styles.selectedFile}>
          <span className={styles.selected}>{fileName}</span>

          <button type="button" onClick={onReset}>
            <ResetFileIcon className={styles.resetIcon} />
          </button>
        </div>
      ) : null}
    </label>
  );
};

export default FileField;
