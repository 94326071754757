import { useState } from "react";
import styles from "./styles/reviews.module.sass";
import { useTranslation } from "react-i18next";

const ReviewCard = ({ children, authorName, authorRole }) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const scrollToStart = () => {
    const element = document.getElementById("card");
    element.scrollIntoView({ block: "start" });
  };

  const showMoreLessHandler = () => {
    setShowMore(!showMore);
    if (showMore) {
      scrollToStart();
    }
  };

  return (
    <div className={styles.reviewCardBox} id="card">
      <div className={styles.reviewCardContentBox}>
        <div className={styles.reviewCardAuthor}>
          <p className={styles.authorData}>
            {authorName}
            <span>{authorRole}</span>
          </p>
        </div>
        <div className={styles.reviewCustomer}>
          <div
            className={[
              styles.reviewCardText,
              showMore ? styles.showContent : styles.hideContent,
            ].join(" ")}
          >
            {children}
          </div>
        </div>
        <div className={styles.reviewButton}>
          <button onClick={showMoreLessHandler}>
            {showMore ? t('cases.reviewCard.readLess') : t('cases.reviewCard.readMore')}
          </button>
          <svg
            viewBox="0 0 80 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
              fill="#4EB5FF"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
