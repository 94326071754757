import { useEffect } from "react";
import useMedia from "../../../hooks/useMedia";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { fetchMedia } from "../../../features/media-slice";
import dateFormat from "dateformat";
import ClockIcon from "../../../images/icons/ClockIcon.svg";
import { ReactComponent as ResetIcon } from "../../../images/icons/resetFile.svg";
import styles from "./posts.module.sass";
import { useDispatch, useSelector } from "react-redux";

function PostsData({
  tags,
  onChooseTag,
  activeTagId,
  page,
  totalPages,
  setPage,
  tagsObj
}) {
  const dispatch = useDispatch();
  const { mediaObj } = useSelector((state) => state.media)
  const { posts } = useSelector((state) => state.posts);
  const { isMobile } = useMedia();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    if (posts.length){
      const includeIds = posts.map(post => post.featured_media)
      dispatch(fetchMedia({ include: includeIds }));
    }
  }, [dispatch, posts]);

  if (!posts || posts.length === 0) return <p>No data</p>;

  return (
    <div>
      {tags.map(({ name, id }) => {
        return (
          <button
            key={id}
            className={(activeTagId === id
              ? [styles.choosenTag]
              : [styles.unchoosenTag]
            ).join(" ")}
          >
            #{name}
            <ResetIcon
              className={styles.resetIcon}
              onClick={() => {
                onChooseTag(null);
              }}
            />
          </button>
        );
      })}

      { posts.map(({ slug, date, title, excerpt, tags, featured_media }, index) => {
        const datePosts = new Date(date);
        return (
          <div key={index} className={styles.posts}>
            <Link to={`/blog/${slug}`}>
              <div className={styles.postCard}>
                <div className={styles.postBlock}>
                  <p className={styles.postsDate}>
                    {dateFormat(datePosts, "mmmm d, yyyy")}
                  </p>
                  <div className={styles.postsTime}>
                    <img
                      src={ClockIcon}
                      placeholder="blur"
                      alt="ClockIcon"
                      loading="eager"
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: excerpt.rendered.slice(134, 150),
                      }}
                      className={styles.time}
                    />
                    <p>min</p>
                  </div>
                  <h2
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                    className={styles.h2}
                  />
                </div>
                <div className={styles.imgs}>
                  {mediaObj && (
                    <img
                      className={styles.postImgs}
                      src={mediaObj[featured_media]}
                      loading="eager"
                      alt="postMedia"
                    />
                  )}
                </div>
              </div>
            </Link>
            <div className={styles.tags}>
              {isMobile
                ? tagsObj &&
                  tags.slice(0, 3).map((tagId, index) => {
                    return (
                      <button
                        key={index}
                        className={(activeTagId === tagId
                          ? [styles.choosenTag]
                          : [styles.postTags]
                        ).join(" ")}
                        onClick={() => {
                          onChooseTag(tagId);
                        }}
                      >
                        #{tagsObj[tagId]}
                      </button>
                    );
                  })
                : tagsObj &&
                  tags.map((tagId, index) => {
                    return (
                      <button
                        key={index}
                        className={(activeTagId === tagId
                          ? [styles.choosenTag]
                          : [styles.postTags]
                        ).join(" ")}
                        onClick={() => {
                          onChooseTag(tagId);
                        }}
                      >
                        #{tagsObj[tagId]}
                      </button>
                    );
                  })}
            </div>
          </div>
        );
      })}
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <div className={styles.paginationButtons}>
            <button
              className={styles.prevPageButton}
              onClick={() => {
                setPage(page - 1);
                scrollToTop();
              }}
              disabled={page <= 1}
            >
              <span>Previous Page</span>
            </button>
            <button
              className={styles.nextPageButton}
              onClick={() => {
                setPage(page + 1);
                scrollToTop();
              }}
              disabled={page === totalPages}
            >
              <span>Next Page</span>
            </button>
          </div>
          <div className={styles.pages}>
            <span className={styles.currentPage}>{page}</span>
            <span className={styles.totalPages}>of {totalPages} pages</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default PostsData;
