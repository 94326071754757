const pathMapper = {
  career: "/career",
  caseStudies: "/case-studies",
  crexi: "/case-studies/crexi",
  free2move: "/case-studies/free2move",
  godo: "/case-studies/godo",
  techMonet: "/case-studies/techMonet",
  company: "/company",
  contacts: "/contacts",
  cookiePolicy: "/cookie-policy",
  faq: "/faq",
  home: "/",
  fullStackDeveloper: "/job-offer/FullStackDeveloper",
  backEndDeveloper: "/job-offer/BackEndDeveloper",
  privacyPolicy: "/privacy-policy",
  consultingAndAudit: "/services/consulting&audit",
  manualTesting: "/services/manual-testing",
  testAutomation: "/services/test-automation",
  QASubscription: "/services/qa-subscription",
  termsAndConditions: "/terms&conditions",
  blog: "/blog",
  blogCategory: "/blog/category/:categorySlug",
  post: "/blog/:postSlug" ,
  trial: "/trial"
};

export default pathMapper;
