import { useDispatch, useSelector } from "react-redux";
import useOutsideClick from "hooks/useOutsideClick";
import { closeModal } from "features/modal-slice";

import styles from "./thanksModal.module.sass";

const ThanksModal = ({
  name,
  title,
  body,
  bg = "subscribe",
}) => {
  const opened = useSelector((state) => state.modal.modals[name]);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal(name));
  };
  const ref = useOutsideClick(close);
  const modalClass = `${opened ? styles.modalActive : ""} ${styles.modalBox} ${
    styles[bg]
  }`;

  return (
    <div className={modalClass} ref={ref}>
      <button className={styles.buttonClose} onClick={close}>
        <svg viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            transform="matrix(0.707107 -0.707107 0.707083 0.707131 0 1.99805)"
            fill="#687380"
          />
          <rect
            transform="matrix(0.707107 0.707107 -0.707083 0.707131 56.5771 1.00781)"
            fill="#687380"
          />
        </svg>
      </button>
      <div className={styles.modalText}>
        <div className={styles.modalTitle}>{title}</div>
        <div className={styles.modalBody}>{body}</div>
      </div>
    </div>
  );
};

export default ThanksModal;
