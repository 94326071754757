import { useState, useCallback } from "react";

const usePagination = (data, volume) => {
  const totalPages = Math.floor(data.length/volume) + (data.length%volume > 0 ? 1 : 0);
  const [page, setPage] = useState(0);
  const setIncreasedPage = useCallback((currentPage) => {
    setPage(currentPage - 1);
  }, []);
  const slicedData = data.slice(page * volume, page * volume + volume);
  return {
    data: slicedData,
    page: page + 1,
    totalPages: totalPages,
    setPage: setIncreasedPage,
  };
};

export default usePagination;
