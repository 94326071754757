import useMedia from "../../../hooks/useMedia";
import { useLocation } from "react-router-dom";
import styles from "./weDoLine.module.sass";
import { useTranslation } from "react-i18next";

const WeDoLine = () => {
  const {t, i18n} = useTranslation();
  const weDoTitle = i18n.resolvedLanguage === 'ru' ? 'weDoTitleRu' : 'weDoTitle'

  const we_string = {
    "/services/manual-testing": t('servicesPage.weDoLine.we_string.key1'),
    "/services/test-automation": t('servicesPage.weDoLine.we_string.key2'),
    "/services/consulting&audit": t('servicesPage.weDoLine.we_string.key3'),
    "/services/qa-subscription": t('servicesPage.weDoLine.we_string.key4')
  }
  const do_string = {
    "/services/manual-testing": t('servicesPage.weDoLine.do_string.key1'),
    "/services/test-automation": t('servicesPage.weDoLine.do_string.key2'),
    "/services/consulting&audit": t('servicesPage.weDoLine.do_string.key3'),
    "/services/qa-subscription": t('servicesPage.weDoLine.do_string.key4')
  }

  const { isMobile } = useMedia();
  const router = useLocation();
  return (
    <div className={styles.weDoBox}>
      {isMobile && router.pathname !== "/services/consulting&audit" & "/services/qa-subscription"? (
        <h3 className={styles.weDoTitleMobile}>
          <span>{t('servicesPage.weDoLine.title')}</span>{t('servicesPage.weDoLine.subTitle')}
        </h3>
      ) : (
        <h3 className={styles[weDoTitle]}>
          {(we_string[router.pathname])} <span>{(do_string[router.pathname])}</span>
        </h3>
      )}
    </div>
  );
};

export default WeDoLine;
