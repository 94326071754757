import { useState, useEffect } from "react";
import { $desktop, $tablet } from "constants/breakpoints";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [devices, setDevices] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true,
  });

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();

      setWindowDimensions(dimensions);
      setDevices({
        isMobile: dimensions.width < $tablet,
        isTablet: dimensions.width >= $tablet && dimensions.width < $desktop,
        isDesktop: dimensions.width >= $desktop,
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { ...windowDimensions, ...devices };
}
