import VisitButton from "../../UI/button/VisitButton";
import styles from "../styles/about.module.sass";
import { useTranslation } from "react-i18next";

const AboutCards = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.aboutFoundedCards}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <h3 className={styles.h3}>
            50+ <span> {t('main.aboutCards.fTitle')}</span>
          </h3>
          <p>
            {t('main.aboutCards.fText')}
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <h3 className={styles.h3}>
            30+ <span> {t('main.aboutCards.sTitle')}</span>
          </h3>
          <p>
          {t('main.aboutCards.sText')}
          </p>
          <div className={styles.arrowCardBtn}>
            <VisitButton title={t('main.aboutCards.visitButton')} href="/case-studies" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCards;
