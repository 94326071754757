import styles from "./servicesStrongPoints.module.sass";
import { useTranslation } from "react-i18next";

const ServicesStrongPoints = () => {

  const { t } = useTranslation();

  return (
    <div className={styles.strongPointsBox}>
      <h6 className={styles.strongPointsTitle}>
        <span>{t('servicesPage.servicesStrongPoints.title')} </span>{t('servicesPage.servicesStrongPoints.subTitle')}
      </h6>
    </div>
  );
};

export default ServicesStrongPoints;
