export const linksData = [
  {
    link: "linkin",
    href: "https://www.linkedin.com/company/cherish-dev/",
    key: 1,
  },
  {
    link: "yout",
    href: "https://www.youtube.com/channel/UCKNATYvEOle1aFrPVoBMxbA",
    key: 2,
  },
  {
    link: "twtr",
    href: "https://twitter.com/dev_cherish",
    key: 3,
  },
  {
    link: "fb",
    href: "https://www.facebook.com/CherishDev/",
    key: 4,
  },
];
