import { animateScroll as scroll } from "react-scroll";
import Case from "../../components/Cases/Case";
import IndustriesTitle from "../../components/Cases/IndustriesTitle";
import IndustriesLine from "../../components/Cases/IndustriesLine";
import MainDescription from "../../components/Cases/MainDescription";
import FormBook from "../../components/Main/FormBook/FormBook";

import free2moveImg from "../../images/cases/free2move.svg";
import free2moveImgHover from "../../images/cases/free2moveHover.svg";
import godoImg from "../../images/cases/godo.svg";
import godoImgHover from "../../images/cases/godoHover.svg";
import crexiImg from "../../images/cases/crexi.svg";
import crexiImgHover from "../../images/cases/crexiHover.svg";
import techMonetImg from "../../images/cases/techMonet.svg";
import techMonetImgHover from "../../images/cases/techMonetHover.svg";
import styles from "./styles/cases.module.sass";
import { useTranslation } from "react-i18next";
import usePagination from "hooks/usePagination";

const imgs = {
  free2moveImg,
  free2moveImgHover,
  godoImg,
  godoImgHover,
  crexiImg,
  crexiImgHover,
  techMonetImg,
  techMonetImgHover
}

const CaseStudies = () => {

const {t} = useTranslation();
const caseData = t('cases.caseData', { returnObjects: true });

const { data, page, totalPages, setPage } = usePagination(caseData, 3);

const scrollToTop = () => {
  scroll.scrollToTop();
};

  return (
    <div className={styles.casesBox}>
      <div className={styles.casesMainBox}>
        <MainDescription />
      </div>
      {data.map((item) => {
        return (
          <Case
            key={item.href}
            title={item.title}
            industry={item.industry}
            duration={item.duration}
            staff={item.staff}
            engagement={item.engagement}
            platforms={item.platforms}
            href={item.href}
            img={imgs[item.img]}
            hoverImg={imgs[item.hoverImg]}
        />
        );
      })}
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <div className={styles.paginationButtons}>
            <button
              className={styles.prevPageButton}
              onClick={() => {
                setPage(page - 1);
                scrollToTop();
              }}
              disabled={page <= 1}
            >
              <span>Previous Page</span>
            </button>
            <button
              className={styles.nextPageButton}
              onClick={() => {
                setPage(page + 1);
                scrollToTop();
              }}
              disabled={page === totalPages}
            >
              <span>Next Page</span>
            </button>
          </div>
          <div className={styles.pages}>
            <span className={styles.currentPage}>{page}</span>
            <span className={styles.totalPages}>of {totalPages} pages</span>
          </div>
        </div>
      )}
      <div className={styles.casesIndustries}>
        <IndustriesTitle />
        <div className={styles.industriesLine}>
          <IndustriesLine />
        </div>
      </div>
      <FormBook />
    </div>
  );
};

export default CaseStudies;
