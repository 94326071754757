import { Link } from "react-router-dom";
import { useState } from "react";
import { sendContactForm } from "services/forms";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";

import { ThanksForSubscribe } from "components/modals/Modals";
import TextField from "components/UI/formControls/TextField";
import PrimaryButton from "components/UI/button/PrimaryButton";
import Checkbox from "components/UI/formControls/Checkbox";

import { required, email as emailRule } from "utils/validationRules";
import useValidateForm from "hooks/useValidateForm";
import useCapthca from "hooks/useCaptcha";

import styles from "./feedBackModalForm.module.sass";
import { openErrorModal } from "utils/errors";
import { useTranslation } from "react-i18next";

const formName = "feedBackModalForm";

const FeedBackModalForm = ({ onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [token, updateToken] = useCapthca(formName);

  const openThanksForSubscribe = () => {
    dispatch(openModal(ThanksForSubscribe));
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isPrivacyChecked, setPrivacyChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = useValidateForm(formName);

  let errors = {
    name: false,
    email: false,
    isPrivacyChecked: false,
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("isPrivacyChecked", isPrivacyChecked);
    formData.append("captchaToken", token);

    setIsLoading(true);

    sendContactForm(formData)
      .then(() => {
        openThanksForSubscribe();
        updateToken();
      })
      .catch( (err) => {
        openErrorModal(err, dispatch);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPressSend = (e) => {
    e.preventDefault();
    validateForm(); // trigger form validation
    if (Object.values(errors).every((e) => e === false)) {
      // check that all field has no errors
      sendForm();
    } else {
      console.log("Form is not valid");
    }
  };

  return (
    <form name={formName} className={styles.feedBackForm}>
      <TextField
        placeholder={t('modals.components.textFieldName')}
        value={name}
        onChange={(value, error) => {
          setName(value);
          errors = { ...errors, name: error };
        }}
      />
      <TextField
        placeholder="my_email@gmail.com"
        value={email}
        rules={{ required, email: emailRule }}
        onChange={(value, error) => {
          errors = { ...errors, email: error };
          setEmail(value);
        }}
      />

      <Checkbox
        name="feedBackModalPrivacy"
        value={isPrivacyChecked}
        onChange={() => setPrivacyChecked(!isPrivacyChecked)}
      >
        <p>
          {t('footer.sendingText')}
          <Link to="/privacy-policy" onClick={onClose}>
            {t('footer.privacyLink')}
          </Link>
        </p>
      </Checkbox>
      <div className={styles.button}>
        <PrimaryButton
          text={t('modals.components.primaryButton')}
          onClick={onPressSend}
          isLoading={isLoading}
          disabled={!isPrivacyChecked}
        />
      </div>
    </form>
  );
};

export default FeedBackModalForm;
