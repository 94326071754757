import { useTranslation } from "react-i18next";
import styles from "./styles/slogan.module.sass";

const Slogan = () => {
  const { t } = useTranslation();
  return (
    <ul className={styles.sloganList}>
      <li>{t('slogan.frLine')}</li>
      <li>{t('slogan.sLine')}</li>
      <li>{t('slogan.thLine')}</li>
      <li>{t('slogan.fLine')}</li>
    </ul>
  );
};

export default Slogan;
