import firstStep from "./first.svg";
import secondStep from "./second.svg";
import thirdStep from "./third.svg";
import fourthStep from "./fourth.svg";
import fifthStep from "./fifth.svg";
import sixthStep from "./sixth.svg";
import firstStepRu from "./steps_ru/first.svg";
import secondStepRu from "./steps_ru/second.svg";
import thirdStepRu from "./steps_ru/third.svg";
import fourthStepRu from "./steps_ru/fourth.svg";
import fifthStepRu from "./steps_ru/fifth.svg";
import sixthStepRu from "./steps_ru/sixth.svg";

export const steps = [firstStep, secondStep, thirdStep, fourthStep, fifthStep, sixthStep]
export const ruSteps = [firstStepRu, secondStepRu, thirdStepRu, fourthStepRu, fifthStepRu, sixthStepRu]
