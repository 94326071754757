import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getPosts } from "services/posts";

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (query = {}) => {
  return getPosts(query).then((response) => (
      { data: response.data, totalPosts: Number(response.headers['x-wp-total']) }
  )).catch((err) => err);
});

export const addPosts = createAction("posts/addPosts");

const postSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    loadingPosts: false,
  },
  reducers: {
    addPosts: (state, action) => {
      const postsToAdd = action.payload.filter(post => !state.posts.find(i => i.id === post.id))
      state.posts = [...postsToAdd, ...state.posts];
    },
  },
  extraReducers: {
    [fetchPosts.pending]: (state) => {
      state.loadingPosts = true;
    },
    [fetchPosts.fulfilled]: (state, action) => {
      state.loadingPosts = false;
      state.posts = action.payload.data;
    },
    [fetchPosts.rejected]: (state) => {
      state.loadingPosts = false;
    },
  },
});

export default postSlice.reducer;
