import styles from "./styles/doc.module.sass";
import { useTranslation } from "react-i18next";

const DocFooter = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.docFooterBox}>
      <p className={styles.docFooterText}>{t('docFooter.docFooterText')}</p>
      <div className={styles.docFooterSection}>
        <a href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator">
          {t('docFooter.iubendaLink')}
        </a>
          {t('docFooter.iubendaText')}
        <a href="https://www.iubenda.com/privacy-policy/65675001">
          {t('docFooter.dataLink')}
        </a>
          {t('docFooter.dataText')}
      </div>
    </div>
  );
};

export default DocFooter;
