import styles from "./footerPreloader.module.sass";
import { useTranslation } from "react-i18next";

const FooterPreloader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.preloader}>
      <span>{t('preloader')}</span>
    </div>
  );
};
export default FooterPreloader;
