import { useHistory } from "react-router-dom";
import PrimaryButton from "../../components/UI/button/PrimaryButton";
import page404 from "../../images/imgs/404page.svg";
import styles from "./styles/404.module.sass";

const Page404 = () => {
  const router = useHistory();

  const goHome = () => {
    router.push("/");
  };

  return (
    <div className={styles.page404}>
      <div className={styles.box404}>
        <img
          className={styles.img404}
          src={page404}
          quality={100}
          alt="404"
          loading="eager"
        />
        <div className={styles.btn404}>
          <PrimaryButton text="to main" onClick={goHome} />
        </div>
      </div>
    </div>
  );
};

export default Page404;
