import { useState } from "react";
import { validate } from "utils/validationRules";
import Fail from "../../../images/icons/Failed.svg";
import styles from "./formControls.module.sass";

const TextField = ({
  rules = null,
  placeholder,
  value = "",
  onChange,
  textarea = false,
}) => {
  const [errors, setErrors] = useState([]);
  const [hasError, setHasError] = useState(false);

  const onValueChange = (targetValue) => {
    const valdiation = validate(rules, targetValue);
    const error = valdiation.length !== 0;
    setErrors(valdiation);
    setHasError(error);
    onChange(targetValue, error);
  };

  return (
    <div
      className={`${styles.field} ${hasError ? styles.error : ""} ${
        rules && "required" in rules ? styles.required : ""
      }`}
    >
      {textarea ? (
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          onBlur={() => onValueChange(value)}
        />
      ) : (
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          onBlur={() => onValueChange(value)}
        />
      )}
      {hasError && (
        <span className={styles.message}>
          <img src={Fail} loading="eager" alt="failed" />
          {errors[0]}
        </span>
      )}
    </div>
  );
};

export default TextField;
