import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import arrow from "../../../images/icons/arrowBanner.svg";
import styles from "../styles/banner.module.sass";

const Banner = () => {
  const {t} = useTranslation();

  return (
      <Link className={styles.banner} to="/services/qa-subscription">
        <div className={styles.bannerAdvt}>
          <p className={styles.bannerText}>
            {t('main.subscriptionCard.text')}
          </p>
          <h2 className={styles.bannerTitle}>
            {t('main.subscriptionCard.title')}
          </h2>
        </div>
        <h3 className={styles.bannerSubTitle}>
          {t('main.subscriptionCard.subTitle')}
          <img src={arrow} alt="arrow" />
        </h3>
      </Link>
  );
};

export default Banner;
