import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import AboutProject from "../../components/Cases/AboutProject";
import IndustriesTitle from "components/Cases/IndustriesTitle";
import IndustriesLine from "../../components/Cases/IndustriesLine";
import MainCase from "../../components/Cases/MainCase";
import Rely from "../../components/Cases/Rely";
import ReviewCard from "../../components/Cases/ReviewCard";
import Shares from "../../components/Cases/Shares";
import TaskToPerform from "../../components/Cases/TaskToPerform";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";
import styles from "./styles/cases.module.sass";
import free2moveBg from "../../images/cases/free2moveBg.svg";
import free2moveTools from "../../images/cases/free2moveTools.svg";
import { useTranslation } from "react-i18next";

const Free2Move = () => {
  const {t, i18n} = useTranslation();

  const sharesLinks = i18n.resolvedLanguage === 'ru' ? 'sharesLinksRu' : 'sharesLinks';

  const shareLink = "cherishdev.com/case-studies/free2move";

  const remediesData = t('cases.free2Move.remediesData', { returnObjects: true })

  const goalsData = t('cases.free2Move.goalsData', { returnObjects: true })

  const arrowBtnData = t('cases.free2Move.arrowBtnData', { returnObjects: true })

  return (
    <div className={styles.singleCaseBox}>
      <MainCase
        title="free2move"
        caseImg={free2moveBg}
        requirements={t('cases.free2Move.requirements')}
        nextCase="godo"
        prevCase="techMonet"
      />
      <AboutProject
        companyProfile={t('cases.free2Move.companyProfile')}
        industry={t('cases.free2Move.industry')}
        duration={t('cases.free2Move.duration')}
        staff="7+"
        engagement={t('cases.free2Move.engagement')}
        platforms="Android iOS Web"
        goals={goalsData}
        imgSrc={free2moveTools}
      />
      <TaskToPerform
        problemsTitle
        problemsText={t('cases.free2Move.problemsText')}
        remediesTitle
        remediesData={remediesData}
      />
      <div className={styles.free2moveOutcomesBox}>
        <div className={styles.free2moveOutcomesBoxContent}>
          <h2 className={styles.free2moveOutcomesTitle}>{t('cases.free2Move.outcomesTitle')}</h2>
          {arrowBtnData.map((data) => {
            return (
              <TwoArrowBtn
                key={data.number}
                number={data.number}
                text={data.text}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.reviewsBox}>
        <div className={styles.reviewsContentBox}>
          <h2 className={styles.reviewTitle}>{t('cases.free2Move.reviewTitle')}</h2>
          <ReviewCard
            authorName={"Marouane Ouaou"}
            authorRole={t('cases.free2Move.authorRole')}
          >
            <div className={styles.reviews}>
              <p className={styles.reviewConclusion}>
                {t('cases.free2Move.reviews')}
              </p>
              <div className={styles.reviewDetails}>
                <p>
                  {t('cases.free2Move.reviewDetails.point01')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point02')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point03')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point04')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point05')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point06')}
                </p>
                <p>
                  {t('cases.free2Move.reviewDetails.point07')}
                </p>
              </div>
            </div>
          </ReviewCard>
        </div>
      </div>
      <div className={styles[sharesLinks]}>
        <p>
          {t('cases.free2Move.sharesLink')}<span>(1k+)</span>
        </p>
        <ul className={styles.sharesList}>
          <li>
            <LinkedinShareButton url={shareLink}>linkin</LinkedinShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={shareLink}
              title={t('cases.free2Move.twitterShareLink')}
            >
              twtr
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton url={shareLink}>fb</FacebookShareButton>
          </li>
        </ul>
      </div>
      <Shares prevArrowCaseTitle="techMonet" nextArrowCaseTitle="godo" />
      <div className={styles.casesIndustries}>
        <IndustriesTitle />
        <div className={styles.industriesLine}>
          <IndustriesLine />
        </div>
      </div>
      <Rely />
    </div>
  );
};

export default Free2Move;
