import useMedia from "../../hooks/useMedia";
import ServicesNextArrow from "../../components/Services/ServicesArrows/ServicesNextArrow";
import ServicesPrevArrow from "../../components/Services/ServicesArrows/ServicesPrevArrow";
import ServicesSlogan from "../../components/Services/ServicesSlogan/ServicesSlogan";
import ServicesStrongPoints from "../../components/Services/ServicesStrongPoints/ServicesStrongPoints";
import ServicesTitle from "../../components/Services/ServicesTitle/ServicesTitle";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";
import manualLogo from "../../images/imgs/ManualLogo.svg";

import consultinglogo from "../../images/imgs/ConsultingLogo.svg";
import nextArrow from "../../images/icons/ServicesArrowNext.svg";
import prevArrow from "../../images/icons/ServicesArrowPrev.svg";
import star from "../../images/icons/TrialStar.svg";
import checkbox from "../../images/icons/TrialCheckbox.svg";

import OrderButton from "../../components/UI/button/OrderButton";
import WeDoLine from "../../components/Services/WeDoLine/WeDoLine";
import ServicesScroll from "../../components/Services/ServicesScroll/ServicesScroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import styles from "./styles/servicesPage.module.sass";
import { useTranslation } from "react-i18next";

const QASubscription = () => {
  const {t, i18n} = useTranslation();
  const chooseUsBox = i18n.resolvedLanguage === 'ru' ? 'chooseUsBoxRu' : 'chooseUsBox';

  const scrollData = t('servicesPage.qaSubscription.scrollData', { returnObjects: true });

  const { isMobile } = useMedia();

  return (
    <div>
      <div className={styles.servicesMainBox}>
        {!isMobile ? (
          <div>
            <ServicesNextArrow
              aboveArrText={t('servicesPage.qaSubscription.servicesNextArrow')}
              arrowSrc={nextArrow}
              link="manual-testing"
              imgSrc={manualLogo}
            />
          </div>
        ) : null}
        <div
          className={`${styles.servicesMain} ${styles.servicesQaSubscriptionBg}`}
        >
          <div className={styles.servicesTitle}>
            <ServicesTitle
              fTitle={t('servicesPage.qaSubscription.servicesTitle.fTitle')}
              thTitle={t('servicesPage.qaSubscription.servicesTitle.sTitle')}
              ftTitle={t('servicesPage.qaSubscription.servicesTitle.thTitle')}
            />
          </div>
          <div className={styles.servicesSlogan}>
            <ServicesSlogan slogan={t('servicesPage.qaSubscription.servicesSlogan')}/>
          </div>
        </div>
        {!isMobile ? (
          <ServicesPrevArrow
            aboveArrText={t('servicesPage.qaSubscription.servicesPrevArrow')}
            arrowSrc={prevArrow}
            link="consulting&audit"
            imgSrc={consultinglogo}
          />
        ) : null}
      </div>
      <WeDoLine />
      <ServicesScroll
        items={scrollData}
        defaultItem={scrollData[0]}
        color="#179EFF"
      />
      <div className={styles.servicesPoints}>
        <ServicesStrongPoints />
        {!isMobile ? (
          <div className={styles.benefitsSubscription}>
            <p>
                {t('servicesPage.qaSubscription.benefitsSubscription.frstPoint')}
            </p>
            <p>
                {t('servicesPage.qaSubscription.benefitsSubscription.scdPoint')}
            </p>
            <p>
                {t('servicesPage.qaSubscription.benefitsSubscription.thdPoint')}
            </p>
            <p>
                {t('servicesPage.qaSubscription.benefitsSubscription.frthPoint')}
            </p>
          </div>
        ) : (
          <div className={styles.benefitsSlider}>
            <div className={styles.benefitsContainer}>
              <Swiper
                speed={1000}
                parallax={true}
                keyboard={{
                  enabled: true,
                }}
                pagination={{
                  clickable: true,
                  bulletActiveClass: styles.processPaginationActive,
                }}
                slidesPerView={1}
                spaceBetween={0}
                scrollbar={{
                  hide: false,
                  draggable: true,
                }}
                autoplay={{
                  waitForTransition: true,
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Keyboard, Parallax]}
                className={styles.swiper}
              >
                <div
                  slot="container-start"
                  className={styles.parallaxBgSubscription}
                  data-swiper-parallax="-62%"
                ></div>
                <SwiperSlide className={styles.slide}>
                  <p>
                    {t('servicesPage.qaSubscription.benefitsSubscription.frstPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.text}>
                    {t('servicesPage.qaSubscription.benefitsSubscription.scdPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.textResources}>
                      {t('servicesPage.qaSubscription.benefitsSubscription.thdPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.text}>
                      {t('servicesPage.qaSubscription.benefitsSubscription.frthPoint')}
                  </p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        )}
      </div>
      <div className={styles[chooseUsBox]}>
        <h2 className={styles.chooseTitle}>
          <span>{t('servicesPage.qaSubscription.chooseTitle.title')} </span>{t('servicesPage.qaSubscription.chooseTitle.subTitle')}
        </h2>
        <div>
          <TwoArrowBtn
            number="01"
            text={t('servicesPage.qaSubscription.chooseUsBox.twoArrowBtn01')}
            arrColor="#179EFF"
            borderColor="#179EFF"
            botBorderColor="#179EFF"
          />
        </div>
        <TwoArrowBtn
          number="02"
          text={t('servicesPage.qaSubscription.chooseUsBox.twoArrowBtn02')}
          arrColor="#179EFF"
          borderColor="#179EFF"
          botBorderColor="#179EFF"
        />
        <div>
          <TwoArrowBtn
            number="03"
            text={t('servicesPage.qaSubscription.chooseUsBox.twoArrowBtn03')}
            arrColor="#179EFF"
            borderColor="#179EFF"
            botBorderColor="#179EFF"
          />
        </div>
        <TwoArrowBtn
          number="04"
          text={t('servicesPage.qaSubscription.chooseUsBox.twoArrowBtn04')}
          arrColor="#179EFF"
          borderColor="#179EFF"
          botBorderColor="#179EFF"
        />
      </div>
      <div className={styles.subscription}>
        <h2 className={styles.subscriptionTitle}>{t('servicesPage.qaSubscription.subscription.title')}<span>{t('servicesPage.qaSubscription.subscription.subtitle')}</span></h2>
        <div className={styles.subscriptionCards}>
          <div className={styles.subscriptionPrices}>
            <img
              src={star}
              loading="eager"
              alt="star"
              className={styles.star}
            />
            <h3 className={styles.h3}>
              {t('servicesPage.qaSubscription.subscriptionCards.card01.title')}<span>$300 <strike>($450)</strike></span></h3>
            <div className={styles.subscriptionPoints}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card01.results.frstPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card01.results.frstNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card01.results.scndPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card01.results.scndNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card01.results.thdPoint')}<span>100$</span></p>
            </div>
            <OrderButton />
          </div>
          <div className={styles.subscriptionPrices}>
            <img
              src={star}
              loading="eager"
              alt="star"
              className={styles.star}
            />
            <h3 className={styles.h3}>
              {t('servicesPage.qaSubscription.subscriptionCards.card02.title')}<span>$500 <strike>($750)</strike></span></h3>
            <div className={styles.subscriptionPoints}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.frstPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.frstNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.scndPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.scndNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.thdPoint')}<span>100$</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.frthPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card02.results.frthNote')}</span></p>
            </div>
            <OrderButton />
          </div>
          <div className={styles.subscriptionPrices}>
            <img
              src={star}
              loading="eager"
              alt="star"
              className={styles.star}
            />
            <h3 className={styles.h3}>
              {t('servicesPage.qaSubscription.subscriptionCards.card03.title')}<span>$700 <strike>($1050)</strike></span></h3>
            <div className={styles.subscriptionPoints}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.frstPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.frstNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.scndPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.scndNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.thdPoint')}<span>100$</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.frthPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.frthNote')}</span></p>
            </div>
            <div className={styles.subscriptionPoints}><img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.ffthPoint')}<span>{t('servicesPage.qaSubscription.subscriptionCards.card03.results.ffthNote')}</span></p>
            </div>
            <OrderButton />
          </div>
        </div>
        <ul className={styles.subscriptionRemarks}>
          <li>{t('servicesPage.qaSubscription.subscriptionCards.remarks.frstPoint')}</li>
          <li>{t('servicesPage.qaSubscription.subscriptionCards.remarks.scndPoint')}</li>
        </ul>
      </div>
    </div>
  );
};

export default QASubscription;
