import JobOffer from "../../components/JobOffer/JobOffer";
import PageTrippleTitle from "../../components/UI/pageTitle/PageTrippleTitle";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";
import JobOfferForm from "pages/job-offer/components/JobOfferForm";
import styles from "./styles/career.module.sass";
import { useTranslation } from "react-i18next";

const Career = () => {
  const { t, i18n } = useTranslation();

  const h1 = i18n.resolvedLanguage === 'ru' ? 'h1Ru' : 'h1';

  return (
    <main>
      <div className={styles.careerContainer}>
        <div className={styles.careerMainContent}>
          <PageTrippleTitle
            fTitle={t('career.careerMainContent.fTitle')}
            subTitle={t('career.careerMainContent.subTitle')}
            sTitle={t('career.careerMainContent.sTitle')}
          />
          <p>
            {t('career.careerMainContent.text')}
          </p>
        </div>
        <div className={styles.careerSubContent}>
          <div className={styles.subContentList}>
            <h2 className={styles.h2}>
              {t('career.subContentList.title')} <span>{t('career.subContentList.subTitle')}</span>
            </h2>

            <TwoArrowBtn number="01" text={t('career.subContentList.arrowBtn01')} />
            <TwoArrowBtn number="02" text={t('career.subContentList.arrowBtn02')} />
            <TwoArrowBtn number="03" text={t('career.subContentList.arrowBtn03')} />
            <TwoArrowBtn number="04" text={t('career.subContentList.arrowBtn04')} />
            <TwoArrowBtn number="05" text={t('career.subContentList.arrowBtn05')} />
          </div>
        </div>
        <div className={styles.careerJobsContent}>
          <h3 className={styles.h3}>{t('career.careerJobsContent.title')}</h3>
          <ul className={styles.offers}>
            <li>
              <JobOffer
                link="/job-offer/BackEndDeveloper"
                title={t('career.careerJobsContent.frstOffer')}
              >
                <div className={styles.jobOfferDescription}>
                  <p>
                    {t('career.careerJobsContent.experience')} <span>|</span>
                  </p>
                  <p>
                    {t('career.careerJobsContent.time')} <span>|</span>
                  </p>
                  <p>{t('career.careerJobsContent.location')}</p>
                </div>
              </JobOffer>
            </li>
            <li>
              <JobOffer
                link="/job-offer/FullStackDeveloper"
                title={t('career.careerJobsContent.scndOffer')}
              >
                <div className={styles.jobOfferDescription}>
                  <p>
                    {t('career.careerJobsContent.experience')} <span>|</span>
                  </p>
                  <p>
                    {t('career.careerJobsContent.time')} <span>|</span>
                  </p>
                  <p>{t('career.careerJobsContent.location')}</p>
                </div>
              </JobOffer>
            </li>
          </ul>
          <div className={styles.careerForm}>
            <h1 className={styles[h1]}>
              {t('career.careerForm.title')} <br />
              <span>{t('career.careerForm.subTitle')}</span>
            </h1>
            <JobOfferForm />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Career;
