import { useEffect } from "react";
import { useSelector } from "react-redux";

import ContactUs from "./ContactUsModal/ContactUsModal";
import Feedback from "./GetFeedbackModal/FeedbackModal";
import Cooperate from "./CooperateModal/CooperateModal";
import FreeGuide from "./FreeGuideModal/FreeGuideModal";
import Follow from "./FollowModal/FollowModal";
import RecaptchaError from "./RecaptchaErrorModal/RecaptchaErrorModal";
import Response from "./ThanksModal/ThanksModal";
import Error from "./ErrorModal/ErrorModal";
import { useTranslation } from "react-i18next";

const modalComponents = {
  ContactUsModal: (name) => <ContactUs name={name} key={name} />,
  FeedbackModal: (name) => <Feedback name={name} key={name} />,
  CooperateModal: (name) => <Cooperate name={name} key={name} />,
  FreeGuideModal: (name) => <FreeGuide name={name} key={name} />,
  FollowModal: (name) => <Follow name={name} key={name} />,
  ThanksForContact: (name, t) => (
    <Response
      bg="contact"
      name={name}
      key={name}
      title={
        <>
            {t('modals.thanksForContact.title')} <span>{t('modals.thanksForContact.subTitle')}</span>
        </>
      }
      body={t('modals.thanksForContact.text')}
    />
  ),
  ThanksForQuestion: (name, t) => (
    <Response
      bg="question"
      name={name}
      key={name}
      title={
        <>
            {t('modals.thanksForQuestion.title')} <span>{t('modals.thanksForQuestion.subTitle')}</span>
        </>
      }
      body={t('modals.thanksForContact.text')}
    />
  ),
  ThanksForSubscribe: (name, t) => (
    <Response
      name={name}
      key={name}
      title={
        <>
            {t('modals.thanksForSubscribe.title')} <span>{t('modals.thanksForSubscribe.subTitle')}</span>
        </>
      }
      body={t('modals.thanksForSubscribe.text')}
    />
  ),
  ThanksForResponse: (name, t) => (
    <Response
      bg="response"
      name={name}
      key={name}
      title={
        <>
            {t('modals.thanksForResponse.title')} <span>{t('modals.thanksForResponse.subTitle')}</span>
        </>
      }
      body={t('modals.thanksForResponse.text')}
    />
  ),
  ThanksForDownload: (name, t) => (
    <Response
      bg="download"
      name={name}
      key={name}
      body={t('modals.thanksForDownload.text')}
      title={
        <>
          {t('modals.thanksForDownload.title')}<span>{t('modals.thanksForDownload.subTitle')}</span>
        </>
      }
    />
  ),
  ErrorModal: (name) => <Error name={name} key={name} />,
  RecaptchaErrorModal: (name) => <RecaptchaError name={name} key={name} />,
  ThanksForSubscriptionFooter: (name, t) => (
    <Response
      name={name}
      key={name}
      title={
        <>
            {t('modals.thanksForSubscriptionFooter.title')} <span>{t('modals.thanksForSubscriptionFooter.subTitle')}</span>
        </>
      }
      body=""
    />
  ),
};

const Modals = () => {
  const {t} = useTranslation();
  const modals = useSelector((state) => state.modal.modals);
  const modalsKeys = Object.keys(modals);

  useEffect(() => {
    const opened = modalsKeys.some((modal) => modals[modal]);
    const wrapper = document.querySelector("html");
    if (opened && wrapper) {
      wrapper.style.overflow = "hidden";
    } else {
      wrapper.style.overflow = "auto";
    }
    return () => {
      if (wrapper) wrapper.style.overflow = "auto";
    };
  }, [modalsKeys, modals]);

  return modalsKeys.map((modalName) => modalComponents[modalName](modalName,t));
};

export const {
  ContactUsModal,
  FeedbackModal,
  CooperateModal,
  FreeGuideModal,
  FollowModal,
  ThanksForContact,
  ThanksForQuestion,
  ThanksForSubscribe,
  ThanksForResponse,
  ThanksForDownload,
  ErrorModal,
  RecaptchaErrorModal,
  ThanksForSubscriptionFooter,
} = Object.keys(modalComponents).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {}
);

export default Modals;
