import styles from "./formControls.module.sass";

const Checkbox = ({
    name,
    onChange,
    value,
    children,
}) => {
    return (
        <div className={styles.checkbox}>
            <input
                id={name}
                name={name}
                type="checkbox"
                value={value}
                onChange={onChange}
                defaultChecked={value}/>
            <label htmlFor={name}>
                {children}
            </label>
        </div>
    );
};

export default Checkbox;
