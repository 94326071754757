import axios from "axios";

const apiUrlPosts =
  "https://blog.cherishdev.com/wp-json/wp/v2/posts";

const apiUrlCategories =
  "https://www.blog.cherishdev.com/wp-json/wp/v2/categories";

const apiUrlTags =
  "https://www.blog.cherishdev.com/wp-json/wp/v2/tags/?per_page=100";

const apiUrlMedia =
  " https://www.blog.cherishdev.com/wp-json/wp/v2/media/?per_page=100";

export const getPosts = (params = {}) => {
  return axios.get(apiUrlPosts, { params });
};

export const getCategories = () => {
  return axios.get(apiUrlCategories);
};

export const getTags = (params = {}) => {
  return axios.get(apiUrlTags, { params });
};

export const getMedia = (params = {}) => {
  return axios.get(apiUrlMedia, { params });
};
