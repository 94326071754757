import { Link } from "react-router-dom";
import { useState } from "react";
import { sendFollow } from "services/forms";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";

import { ThanksForSubscriptionFooter } from "components/modals/Modals";
import TextField from "components/UI/formControls/TextField";
import PrimaryButton from "components/UI/button/PrimaryButton";
import Checkbox from "components/UI/formControls/Checkbox";

import { required, email as emailRule } from "utils/validationRules";
import useValidateForm from "hooks/useValidateForm";
import useCapthca from "hooks/useCaptcha";

import styles from "./followModalForm.module.sass";
import { openErrorModal } from "../../../../utils/errors";
import { useTranslation } from "react-i18next";

const formName = "FollowModalForm";

const FollowModalForm = ({ onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [token, updateToken] = useCapthca(formName);

  const openThanksForDownload = () => {
    dispatch(openModal(ThanksForSubscriptionFooter));
  };

  const [email, setEmail] = useState("");
  const [isPrivacyChecked, setPrivacyChecked] = useState(true);
  const [isSubscribeChecked, setSubscribeChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = useValidateForm(formName);

  let errors = {
    email: false,
    isPrivacyChecked: false,
    isSubscribeChecked: false,
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("isPrivacyChecked", isPrivacyChecked);
    formData.append("isSubscribeChecked", isSubscribeChecked);
    formData.append("captchaToken", token);

    setIsLoading(true);

    sendFollow(formData)
      .then(() => {
        openThanksForDownload();
        updateToken();
      })
      .catch((err) => {
        openErrorModal(err, dispatch)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPressSend = (e) => {
    e.preventDefault();
    validateForm(); // trigger form validation
    if (Object.values(errors).every((e) => e === false)) {
      sendForm();
    } else {
      console.log("Form is not valid");
    }
  };

  return (
    <form name={formName} className={styles.followModalForm}>
      <TextField
        placeholder="my_email@gmail.com"
        value={email}
        rules={{ required, email: emailRule }}
        onChange={(value, error) => {
          errors = { ...errors, email: error };
          setEmail(value);
        }}
      />
      <Checkbox
          name="followModalPrivacy"
          value={isPrivacyChecked}
          onChange={() => setPrivacyChecked(!isPrivacyChecked)}
      >
        <p>
          {t('footer.sendingText')}
          <Link to="/privacy-policy" onClick={onClose}>
            {t('footer.privacyLink')}
          </Link>
        </p>
      </Checkbox>
      <Checkbox
          name="followModalSubscribe"
          value={isSubscribeChecked}
          onChange={() => setSubscribeChecked(!isSubscribeChecked)}
      >
        <p>
          <span>{t('footer.subscribeNews')}</span> {t('footer.unsubscribe')}
        </p>
      </Checkbox>
      <div className={styles.button}>
        <PrimaryButton
          text={t('subscribeButton')}
          onClick={onPressSend}
          isLoading={isLoading}
          disabled={!(isPrivacyChecked && isSubscribeChecked)}
        />
      </div>
    </form>
  );
};
export default FollowModalForm;
