import { useDispatch } from "react-redux";

import { openModal } from "features/modal-slice";
import { FeedbackModal } from "components/modals/Modals";
import styles from "../styles/formAsk.module.sass";
import { useTranslation } from "react-i18next";

const FormAsk = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const openFeedbackModal = () => {
    dispatch(openModal(FeedbackModal));
  };
  return (
    <>
      <div className={styles.formAskBox}>
        <p>
          {t('main.formAsk.formAskBox')}
        </p>
        <div className={styles.formAskText} onClick={openFeedbackModal}>
          <h2 className={styles.h2}>{t('main.formAsk.formAskText')}</h2>
          <svg
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
              fill="#FFF"
            />
          </svg>
        </div>
        <h2 className={styles.h2}>{t('main.formAsk.formAskTitle')}</h2>
      </div>
    </>
  );
};

export default FormAsk;
