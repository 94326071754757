import { useEffect, useState } from "react";
import useMedia from "../../hooks/useMedia";
import { Link } from "react-router-dom";
import { getPosts } from "services/posts";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import { useParams } from "react-router-dom";
import { fetchMedia } from "../../features/media-slice";
import { fetchTags } from "../../features/tags-slice";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosts, addPosts } from "features/post-slice";
import dateFormat from "dateformat";
import BackButton from "components/UI/button/BackButton";
import VisitButton from "components/UI/button/VisitButton";
import Follow from "components/Blog/Follow";
import LoadingData from "./data/LoadingData";
import ClockIcon from "../../images/icons/ClockIcon.svg";
import styles from "./post.module.sass";

const Post = () => {
  const dispatch = useDispatch();
  const { posts, loadingPosts } = useSelector((state) => state.posts);
  const { mediaObj } = useSelector((state) => state.media)
  const { tagsObj } = useSelector((state) => state.tags)
  const { isMobile } = useMedia();
  const { postSlug } = useParams();
  const shareLink = `cherishdev.com/blog/${postSlug}`;
  const [currentPost, setCurrentPost] = useState(null);

  useEffect(() => {
    if (posts.length) {
      const post = posts.find((p) => p.slug === postSlug);
      setCurrentPost(post);
      if (posts.length < 5) getPosts({ per_page: 5 }).then(res => dispatch(addPosts(res.data)))
    } else {
      dispatch(fetchPosts({ slug: postSlug }));
      getPosts({ per_page: 5 }).then(res => dispatch(addPosts(res.data)))
    }
  }, [posts, postSlug, dispatch]);

  useEffect(() => {
    const includeIds = posts.map(post => post.tags).flat()
    if (includeIds.length){
      dispatch(fetchTags({ include: includeIds }))
    }
  }, [dispatch, posts]);

  useEffect(() => {
      const includeIds = posts.map(post => post.featured_media)
      if (includeIds.length) {
        dispatch(fetchMedia({ include: includeIds }));
      }
      }, [dispatch, posts]);

  return (
    <>
      <div className={styles.post}>
        <LoadingData isLoading={loadingPosts}>
        <BackButton title="BACK TO ARTICLES" href="/blog" />
        <div className={styles.article}>
          {currentPost && (
            <h1
              dangerouslySetInnerHTML={{ __html: currentPost.title.rendered }}
              className={styles.postTitle}
            />
          )}
          <div className={styles.imgPost}>
            {currentPost && mediaObj && (
              <img
                className={styles.postImgs}
                src={mediaObj[currentPost.featured_media]}
                loading="eager"
                alt="postMedia"
              />
            )}
            <div className={styles.sharesLinks}>
              <p>
                Shares<span>(1k+)</span>
              </p>
              <ul className={styles.sharesList}>
                <li>
                  <LinkedinShareButton url={shareLink}>
                    linkin
                  </LinkedinShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    url={shareLink}
                    title="Check out our educational QA content to grow professionally with us!"
                  >
                    twtr
                  </TwitterShareButton>
                </li>
                <li>
                  <FacebookShareButton url={shareLink}>fb</FacebookShareButton>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.postDate}>
            {currentPost && (
              <p className={styles.date}>
                {dateFormat(new Date(currentPost.date), "mmmm d, yyyy")}
              </p>
            )}
            {currentPost && (
              <div className={styles.articleTime}>
                <img
                  src={ClockIcon}
                  placeholder="blur"
                  alt="ClockIcon"
                  loading="eager"
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: currentPost.excerpt.rendered.slice(134, 150),
                  }}
                  className={styles.time}
                />
                <p>min</p>
              </div>
            )}
          </div>
          {currentPost && (
            <div
              dangerouslySetInnerHTML={{
                __html: currentPost.content.rendered
                  .slice(195)
                  .replace("pan>", ""),
              }}
              className={styles.postContent}
            />
          )}
          <div className={styles.articleTags}>
            {currentPost &&
              tagsObj &&
              currentPost.tags.map((tagId, index) => {
                return (
                  <p className={styles.tags} key={index}>
                    #{tagsObj[tagId]}
                  </p>
                );
              })}
          </div>
        </div>

        <div className={styles.moreArticles}>
          <h2 className={styles.h2}>
            more<span>articles</span>
          </h2>
          {posts
            .slice(0, 3)
            .map(
              ({ slug, date, title, excerpt, tags, featured_media }, index) => {
                const datePosts = new Date(date);
                return (
                  <div key={index} className={styles.posts}>
                    <Link to={`/blog/${slug}`}>
                      <div className={styles.postCard}>
                        <div className={styles.postBlock}>
                          <p className={styles.postsDate}>
                            {dateFormat(datePosts, "mmmm d, yyyy")}
                          </p>
                          <div className={styles.postsTime}>
                            <img
                              src={ClockIcon}
                              placeholder="blur"
                              alt="ClockIcon"
                              loading="eager"
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: excerpt.rendered.slice(134, 150),
                              }}
                              className={styles.time}
                            />
                            <p>min</p>
                          </div>
                          <h2
                            dangerouslySetInnerHTML={{ __html: title.rendered }}
                            className={styles.h2}
                          />
                        </div>
                        <div className={styles.imgs}>
                          {mediaObj && (
                            <img
                              className={styles.postImgs}
                              src={mediaObj[featured_media]}
                              loading="eager"
                              alt="postMedia"
                            />
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className={styles.tags}>
                      {isMobile
                        ? tagsObj &&
                          tags.slice(0, 3).map((tagId, index) => {
                            return (
                              <p className={styles.postTags} key={index}>
                                #{tagsObj[tagId]}
                              </p>
                            );
                          })
                        : tagsObj &&
                          tags.map((tagId, index) => {
                            return (
                              <p className={styles.postTags} key={index}>
                                #{tagsObj[tagId]}
                              </p>
                            );
                          })}
                    </div>
                  </div>
                );
              }
            )}
        </div>
        <div className={styles.articlesButton}>
          <VisitButton title="All Articles" href="/blog" />
        </div>
        </LoadingData>
      </div>
      <Follow />
    </>
  );
};

export default Post;
