import BackButton from "../UI/button/BackButton";
import styles from "./styles/doc.module.sass";
import { useTranslation } from "react-i18next";

const DocTitle = ({ title }) => {

  const { t } = useTranslation();
  
  return (
    <div className={styles.docTitleBox}>
      <BackButton title={t('backButton')} />
      <h1 className={styles.h1}>
        {title}
        <br />
        <span>{t('docTitleSpan')}</span>
      </h1>
    </div>
  );
};

export default DocTitle;
