import styles from "./styles/pageTitle.module.sass";

const PageTrippleTitle = ({ fTitle, subTitle, sTitle }) => {
  return (
    <h6 className={styles.mainPrimaryTitle}>
      {fTitle}
      <br />
      <span className={styles.mainSubTitle} lang="en">
        {subTitle}
      </span>
      <br />
      {sTitle}
    </h6>
  );
};

export default PageTrippleTitle;
