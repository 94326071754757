import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchPosts } from "features/post-slice";
import { fetchCategories } from "../../features/categories-slice";
import { fetchTags } from "../../features/tags-slice";
import { animateScroll as scroll } from "react-scroll";
import PostsData from "./data/PostsData";
import CategoriesData from "./data/CategoriesData";
import Follow from "components/Blog/Follow";
import LoadingData from "./data/LoadingData";
import styles from "./blog.module.sass";
import { openErrorModal } from "../../utils/errors";
import usePagination from "../../hooks/usePagination";
import { range } from "../../utils/range";

const Blog = () => {
  const dispatch = useDispatch();
  const { categorySlug } = useParams();
  const { posts, loadingPosts } = useSelector((state) => state.posts);
  const { categories, loadingCategories } = useSelector((state) => state.categories);
  const { tagsObj, tags } = useSelector((state) => state.tags)
  const [categoryId, setCategoryId] = useState()
  const [activeTagId, setActiveTagId] = useState(null);
  const [totalPosts, setPostsTotal] = useState(0)
  const { page, totalPages, setPage } = usePagination(range(0, totalPosts), 5);
  const didMount = useRef(false);
  const tagChanged = useRef(false)
  const triggerOnPage = useRef(true)

  useEffect(() => {
    if (categories.length) {
      setCategoryId(categories.find(c => c.slug === categorySlug)?.id ?? 0)
      setPage(1);
      setActiveTagId(null)
    }
  }, [categorySlug, categories, setPage]);

  useEffect(() => {
      if (page !== 1 && tagChanged.current) {
        triggerOnPage.current = false;
        setPage(1)
      }
      tagChanged.current = false
  }, [setPage, activeTagId, page])

  useEffect(() => {
    if (typeof categoryId === 'number' && triggerOnPage.current) {
      const params = { per_page:5, page }
      if (activeTagId) params.tags = [activeTagId]
      if (categoryId) params.categories = [categoryId]
        dispatch(fetchPosts(params)).then((response) => {
          if (response.payload?.name === "AxiosError") {
            openErrorModal(response, dispatch);
          } else {
            setPostsTotal(response.payload.totalPosts)
          }
        });
      } else triggerOnPage.current = true
  }, [dispatch, page, categoryId, activeTagId]);

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch]);

  useEffect(() => {
    if (didMount.current) {
      const includeIds = posts.map(post => post.tags).flat()
      dispatch(fetchTags({ include: includeIds }))
    } else {
      didMount.current = true;
    }
  }, [dispatch, posts]);

  const chooseTag = (tagId) => {
    setActiveTagId(tagId);
    tagChanged.current = true
    if (tagId) {
      scroll.scrollToTop();
    }
  };

  return (
    <>
      <div className={styles.blog}>
        <h1 className={styles.h1}>blog</h1>
        <LoadingData isLoading={loadingPosts || loadingCategories}>
          <CategoriesData
            categories={categories}
          />
          <PostsData
            tags={tags}
            onChooseTag={chooseTag}
            activeTagId={activeTagId}
            page={page}
            totalPages={totalPages}
            setPage={setPage}
            tagsObj={tagsObj}
          />
        </LoadingData>
      </div>
      <Follow />
    </>
  );
};

export default Blog;
