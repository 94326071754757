import cases from "../../images/cases/Cases.svg";
import styles from "./styles/description.module.sass";
import { useTranslation } from "react-i18next";

const MainDescription = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.descrBox}>
      <div className={styles.descrMainBox}>
        <div className={styles.descrImg}>
          <img src={cases} loading="eager" alt="case" />
        </div>
        <h1 className={styles.descrTitle}>
          {t('cases.mainDescription.title')}
          <br />
          <span>{t('cases.mainDescription.subTitle')}</span>
        </h1>
        <div className={styles.descrContent}>
          <p className={styles.descrText}>
            {t('cases.mainDescription.text')}
          </p>
          <p className={styles.descr}>{t('cases.mainDescription.descr')}</p>
        </div>
      </div>
    </div>
  );
};

export default MainDescription;
