import { Link } from "react-router-dom";
import Accordion from "./../../components/Accordion/Accordion";
import FaqForm from "./components/FaqForm";
import styles from "./styles/faq.module.sass";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t, i18n } = useTranslation();

  const FormTitle = i18n.resolvedLanguage === 'ru' ? 'FormTitleRu' : 'FormTitle';
  const boxfaqClass = i18n.resolvedLanguage === 'ru' ? 'boxfaqRu' : 'boxfaq';

  return (
    <div className={styles.faq}>
      <div className={styles[boxfaqClass]}>
        <header>
          <h1 className={styles.h1}>
            {t('faqPage.header.h1Title')}<span>{t('faqPage.header.h1Subtitle')}</span>
          </h1>
          <h2 className={styles.h2}>
            {t('faqPage.header.h2Title')}
            <br /> <span>{t('faqPage.header.h2Subtitle')}</span>
          </h2>
        </header>
        <main className={styles.accordion}>
          <Accordion num="01" title={t('faqPage.accordion01.title')}>
            <p>
              {t('faqPage.accordion01.text')}
              <br />
              JS: Cypress, Playwright
              <br />
              Java: Selenium WebDriver, Cucumber, Appium, REST Assured
              <br />
              Python: Selenium WebDriver, Pytest
            </p>
          </Accordion>
          <Accordion
            num="02"
            title={t('faqPage.accordion02.title')}
          >
            <p>
              {t('faqPage.accordion02.text')}
            </p>
          </Accordion>
          <Accordion
            num="03"
            title={t('faqPage.accordion03.title')}
          >
            <p>
              {t('faqPage.accordion03.text')}
            </p>
          </Accordion>
          <Accordion num="04" title={t('faqPage.accordion04.title')}>
            <p>
              {t('faqPage.accordion04.text')}
            </p>
          </Accordion>
          <Accordion
            num="05"
            title={t('faqPage.accordion05.title')}
          >
            <p>
              {t('faqPage.accordion05.text')}
            </p>
            <Link to="/career">{t('faqPage.accordion05.link')}</Link>
          </Accordion>
          <Accordion num="06" title={t('faqPage.accordion06.title')}>
            <p>
              {t('faqPage.accordion06.text')}
            </p>
            <Link to="/">{t('faqPage.accordion06.link')}</Link>
          </Accordion>
          <Accordion
            num="07"
            title={t('faqPage.accordion07.title')}
          >
            <p>
              {t('faqPage.accordion07.text')}
            </p>
          </Accordion>
          <Accordion num="08" title={t('faqPage.accordion08.title')}>
            <p>
              {t('faqPage.accordion08.text')}
            </p>
            <Link to="/company">{t('faqPage.accordion08.link')}</Link>
          </Accordion>
          <Accordion num="09" title={t('faqPage.accordion09.title')}>
            <p>
              {t('faqPage.accordion09.text')}
            </p>
            <Link to="/services/consulting&audit">
              {t('faqPage.accordion09.link')}
            </Link>
          </Accordion>
        </main>
        <div className={styles.FaqForm}>
          <h2 className={styles[FormTitle]}>
            {t('faqPage.formTitle')}?
            <br />
            <span>{t('faqPage.formSubTitle')}</span>
          </h2>
          <FaqForm />
        </div>
      </div>
    </div>
  );
};

export default Faq;
