import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from 'store';
import App from './App';

import './i18n';

const container = document.getElementById('root');

createRoot(container).render(
    <Provider store={store}>
      <App />
    </Provider>
)
