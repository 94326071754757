import { useState } from "react";
import Icon from "../../images/icons/iconAccordionMobile.svg";
import styles from "./styles/accordion.module.sass";

function AccordionMobile({ title, children }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.accordionItemMobile}>
      <div
        className={styles.accordionTitleMobile}
        onClick={() => setIsActive(!isActive)}
      >
        <h3 className={styles.h3}>{title}</h3>
        <div
          className={styles.accordionArrowMobile}
          style={
            isActive
              ? { transform: "rotate(0deg)" }
              : { transform: "rotate(180deg)" }
          }
        >
          <img src={Icon} alt="arrow" />
        </div>
      </div>
      {isActive && <div className={styles.accordionBodyMobile}>{children}</div>}
    </div>
  );
}

export default AccordionMobile;
