import Preloader from "../preloader/Preloader";
import styles from "./styles/button.module.sass";

const PrimaryButton = ({ text, onClick, isLoading, disabled }) => {
  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <button className={styles.button} onClick={onClick} disabled={disabled}>
          {text}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
