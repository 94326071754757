import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import AboutProject from "../../components/Cases/AboutProject";
import IndustriesTitle from "components/Cases/IndustriesTitle";
import IndustriesLine from "../../components/Cases/IndustriesLine";
import MainCase from "../../components/Cases/MainCase";
import Rely from "../../components/Cases/Rely";
import ReviewCard from "../../components/Cases/ReviewCard";
import Shares from "../../components/Cases/Shares";
import TaskToPerform from "../../components/Cases/TaskToPerform";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";
import styles from "./styles/cases.module.sass";
import crexiBg from "../../images/cases/crexiBg.svg";
import crexiTools from "../../images/cases/crexiTools.svg";
import { useTranslation } from "react-i18next";

const Crexi = () => {

  const {t, i18n} = useTranslation();

  const sharesLinks = i18n.resolvedLanguage === 'ru' ? 'sharesLinksRu' : 'sharesLinks';

  const shareLink = "cherishdev.com/case-studies/crexi";

  const remediesData = t('cases.crexi.remediesData', { returnObjects: true })

  const goalsData = t('cases.crexi.goalsData', { returnObjects: true })

  const arrowBtnData = t('cases.crexi.arrowBtnData', { returnObjects: true })

  return (
    <div className={styles.singleCaseBox}>
      <MainCase
        title="crexi"
        caseImg={crexiBg}
        extension={t('cases.crexi.extension')}
        requirements={t('cases.crexi.requirements')}
        nextCase="techMonet"
        prevCase="godo"
      />
      <AboutProject
        companyProfile={t('cases.crexi.companyProfile')}
        industry={t('cases.crexi.industry')}
        duration={t('cases.crexi.duration')}
        staff="5+"
        engagement={t('cases.crexi.engagement')}
        platforms="Web(inc. API)"
        goals={goalsData}
        imgSrc={crexiTools}
      />
      <TaskToPerform remediesData={remediesData} />
      <div className={styles.free2moveOutcomesBox}>
        <div className={styles.free2moveOutcomesBoxContent}>
          <h2 className={styles.free2moveOutcomesTitle}>{t('cases.crexi.outcomesTitle')}</h2>
          {arrowBtnData.map((data) => {
            return (
              <TwoArrowBtn
                key={data.number}
                number={data.number}
                text={data.text}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.reviewsBox}>
        <div className={styles.reviewsContentBox}>
          <h2 className={styles.reviewTitle}>{t('cases.crexi.reviewTitle')}</h2>
          <ReviewCard
            authorName={"Athenna Peralta"}
            authorRole={t('cases.crexi.authorRole')}
          >
            <div className={styles.reviews}>
              <p>
                {t('cases.crexi.reviews')}
              </p>
              <div className={styles.reviewDetails}>
                <p>
                  {t('cases.crexi.reviewDetails.point01')}
                </p>
                <p>
                  {t('cases.crexi.reviewDetails.point02')}
                </p>
                <p>
                  {t('cases.crexi.reviewDetails.point03')}
                </p>
                <p>
                  {t('cases.crexi.reviewDetails.point04')}
                </p>
                <p>
                  {t('cases.crexi.reviewDetails.point05')}
                </p>
                <p>{t('cases.crexi.reviewDetails.point06')}</p>
              </div>
            </div>
          </ReviewCard>
        </div>
      </div>
      <div className={styles[sharesLinks]}>
        <p>
          {t('cases.crexi.sharesLink')}<span>(1k+)</span>
        </p>
        <ul className={styles.sharesList}>
          <li>
            <LinkedinShareButton url={shareLink}>linkin</LinkedinShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={shareLink}
              title={t('cases.crexi.twitterShareLink')}
            >
              twtr
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton url={shareLink}>fb</FacebookShareButton>
          </li>
        </ul>
      </div>
      <Shares prevArrowCaseTitle="godo" nextArrowCaseTitle="techMonet" />
      <div className={styles.casesIndustries}>
        <IndustriesTitle />
        <div className={styles.industriesLine}>
          <IndustriesLine />
        </div>
      </div>
      <Rely />
    </div>
  );
};

export default Crexi;
