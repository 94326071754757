import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMedia } from "../services/posts";

export const fetchMedia = createAsyncThunk("media/fetchMedia", async (query = {}) => {
  return getMedia(query).then((response) => response.data).catch((err) => err);
});

const mediaSlice = createSlice({
  name: "media",
  initialState: {
    media: [],
    mediaObj: {},
    loadingMedia: false,
  },

  extraReducers: {
    [fetchMedia.pending]: (state) => {
      state.loadingMedia = true;
    },
    [fetchMedia.fulfilled]: (state, action) => {
      state.loadingMedia = false;
      state.media = action.payload;
      const obj = {}
      const allImgs = action.payload;
      allImgs.forEach((i) => {
        obj[i.id] = i.guid.rendered;
      });
      state.mediaObj = obj
    },
    [fetchMedia.rejected]: (state) => {
      state.loadingMedia = false;
    },
  },
});

export default mediaSlice.reducer;
