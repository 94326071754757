import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard } from "swiper";
import Marquee from "react-fast-marquee";
import PageTitle from "../../UI/pageTitle/PageTitle";
import { steps, ruSteps } from "images/steps/index";
import styles from "../styles/process.module.sass";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const Process = () => {
  const { t, i18n } = useTranslation();

  const [body, setBody] = useState();

  useEffect(() => {
    setBody(document.querySelector("html"));
    return () => (body ? (body.style.overflowY = "auto") : null);
  }, [body]);

  const imgData = i18n.resolvedLanguage === "ru" ? ruSteps : steps;

  return (
    <div className={styles.processBox}>
      <div className={styles.processTitle}>
        <PageTitle title={t('main.process.title')} subTitle={t('main.process.subTitle')} />
      </div>
      <div className={styles.processStepsBox}>
        <div className={styles.processSteps}>
          <Swiper
            speed={1000}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
              bulletActiveClass: styles.processPaginationActive,
            }}
            slidesPerView={1}
            spaceBetween={0}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            autoplay={{
              waitForTransition: true,
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Keyboard]}
            className={styles.swiper}
          >
            {imgData?.map((data, index) => {
              return (
                <SwiperSlide key={index} className={styles.slide}>
                  <img alt="process" src={data} loading="eager" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className={styles.processRunningLine}>
        <Marquee
          style={{ overflow: "hidden" }}
          play={true}
          speed={150}
          gradient={false}
        >
          <span className={styles.runningLineStars}>*</span>
          <p>{t('main.process.runningLine')}</p>
          <span className={styles.runningLineStars}>*</span>
          <p>{t('main.process.runningLine')}</p>
        </Marquee>
      </div>
    </div>
  );
};

export default Process;
