import styles from "./loading.module.sass";

function LoadingData({ isLoading, children }) {
  if (!isLoading) return children;
  else
    return (
      <div className={styles.loading}>Please wait, data is loading...</div>
    );
}

export default LoadingData;
