import styles from "./preloader.module.sass";
import { useTranslation } from "react-i18next";

const Preloader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.preloader}>
      <div className={styles.ring}></div>
      <span>{t('preloader')}</span>
    </div>
  );
};
export default Preloader;
