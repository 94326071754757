import { useState } from "react";
import styles from "./styles/accordion.module.sass";
import { ReactComponent as Plus } from "../../images/icons/plus.svg";

const Accordion = ({ num, title, children }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.accordion__item}>
      <div
        className={styles.accordion__title}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={styles.accordion__num}>{num}</div>
        <h3 className={styles.h3}>{title}</h3>
        <div
          className={styles.accordion__plus}
          style={
            isActive
              ? { transform: "rotate(45deg)" }
              : { transform: "rotate(0)" }
          }
        >
          <Plus className={styles.plus} />
        </div>
      </div>
      {isActive && <div className={styles.accordion__body}>{children}</div>}
    </div>
  );
};

export default Accordion;
