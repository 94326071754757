import styles from "./styles/about.module.sass";
import { useTranslation } from "react-i18next";

const AboutProject = ({
  companyProfile,
  industry,
  engagement,
  duration,
  staff,
  platforms,
  goals,
  imgSrc,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.projectBox}>
      <div className={styles.projectContent}>
        <h2>
          {t('cases.aboutProject.title')} <span>{t('cases.aboutProject.subTitle')}</span>
        </h2>
        <div className={styles.projectProfile}>
          <h3>{t('cases.aboutProject.profile')}</h3>
          <p>{companyProfile}</p>
          <div className={styles.projectProfileList}>
            <div className={styles.projectList}>
              <ul className={styles.projectListFirst}>
                <li>
                  {t('cases.case.frPoint')}
                  <br />
                  <span>{industry}</span>
                </li>
                <li>
                  {t('cases.case.scPoint')}
                  <br />
                  <span>{duration}</span>
                </li>
                <li>
                  {t('cases.case.thPoint')}
                  <br />
                  <span>{staff}</span>
                </li>
              </ul>
              <ul className={styles.projectListSecond}>
                <li>
                  {t('cases.case.fthPoint')}
                  <br />
                  <span>{engagement}</span>
                </li>
                <li>
                  {t('cases.case.fifPoint')}
                  <br />
                  <span>{platforms}</span>
                </li>
              </ul>
            </div>
            <ul className={styles.projectGoals}>
              <li>{t('cases.aboutProject.list')}</li>
              {goals.map((data, index) => (
                  <li key={index}>
                    <span>*</span>
                    {data}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.projectToolsImg}>
        <img src={imgSrc} alt="tools" />
      </div>
    </div>
  );
};

export default AboutProject;
