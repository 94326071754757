import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { openModal } from "features/modal-slice";
import useMedia from "../../../hooks/useMedia";
import PrimaryButton from "../../UI/button/PrimaryButton";
import { ContactUsModal } from "components/modals/Modals";
import Shadow from "../../../images/services/Shadow.svg"; 

import styles from "./servicesScroll.module.sass";
import { useTranslation } from "react-i18next";

const ServicesScroll = ({
  items = [],
  defaultItem,
  color,
  title,
  delay = 1500,
  onButtonClick = () => {},
}) => {
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(defaultItem);
  const [translateY, setTranslateY] = useState(0);
  const { isMobile } = useMedia();
  const { t, i18n } = useTranslation();

  const scrollItems = i18n.resolvedLanguage === 'ru' ? 'scrollItemsRu' : 'scrollItems'

  useEffect(() => {
    let index = items.findIndex((i) => items.indexOf(i) === items.indexOf(defaultItem));

    const interval = setInterval(() => {
      setSelectedItem(items[index]);
      index = index + 1 < items.length ? index + 1 : 0;
    }, delay);
    
    return () => {
      clearInterval(interval);
    };
  }, [items, delay, defaultItem]);

  useEffect(() => {
    const activeLi = listRef.current.querySelector(".active");
    
    setTranslateY(activeLi.offsetTop);
  }, [listRef, selectedItem]);

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  return (
    <>
      <div className={styles.scrollBox}>
        <div className={styles[scrollItems]}>
          <ul
            ref={listRef}
            style={{ transform: `translateY(-${translateY}px)` }}
          >
            {items.length &&
              selectedItem &&
              items.map((item) => (
                <li
                  key={items.indexOf(item)}
                  style={items.indexOf(selectedItem) === items.indexOf(item) ? { color } : {}}
                  className={`${items.indexOf(selectedItem) === items.indexOf(item) ? "active" : ""}`}
                >
                  {item}
                </li>
              ))}
          </ul>
          <img className={styles.shadow} src={Shadow} alt="shadow" loading="eager" />
        </div>
        <div className={styles.scrollText}>
          {!isMobile ? <h2>{title}</h2> : null}
          <div className={styles.scrollBtn}>
            <PrimaryButton
              onClick={openContactUsModal}
              text={t('primaryButton')}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesScroll;
