import styles from "./styles/links.module.sass";
import { linksData } from "constants/social";

const Links = () => {
  return (
    <ul className={styles.linksList}>
      {linksData.map((data) => {
        return (
          <li key={data.key}>
            <a href={data.href} target="_blank" rel="noreferrer">
              {data.link}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Links;
