import axios from "axios";

const postContactsUrl =
  "https://auto.cherishdev.com/webhook/725f2ad2-b2fb-4501-b1d2-28594636186b";

export const sendContactForm = (data) => {
  return axios.post(postContactsUrl, data);
};

const postSubscribeUrl =
  "https://auto.cherishdev.com/webhook/ebf63599-9aa0-4f8c-978e-a14ee12e6d5c";

export const sendSubscribe = (data) => {
  return axios.post(postSubscribeUrl, data);
};

const postFollowUrl =
  "https://auto.cherishdev.com/webhook/9e64dd6e-7d9c-4835-a55a-92ad78406115";

export const sendFollow = (data) => {
  return axios.post(postFollowUrl, data);
};
