import styles from "../styles/main.module.sass";

import { useTranslation } from "react-i18next";

const MainServicesLinks = ({ isManual, isAutomation, isConsulting, isSubscription }) => {
  const { t, i18n } = useTranslation();
  
  const changeableListBoxClass = i18n.resolvedLanguage === "ru" ? 'changableListBoxRu': 'changableListBox'

  const manualList = t('main.manualPoints', { returnObjects: true });

  const automationList = t('main.automationPoints', { returnObjects: true});

  const consultingList = t('main.consultingPoints', { returnObjects: true});
  
  const subscriptionList = t('main.subscriptionPoints', { returnObjects: true});

  return (
    <div className={styles[changeableListBoxClass]}>
      <ul className={isManual ? styles.servicesList : styles.hiddenText}>
        {manualList.map((item) => {
          return <li key={manualList.indexOf(item)}>{item}</li>;
        })}
      </ul>
      <ul className={isAutomation ? styles.servicesList : styles.hiddenText}>
        {automationList.map((item) => {
          return <li key={automationList.indexOf(item)}>{item}</li>;
        })}
      </ul>
      <ul className={isConsulting ? styles.servicesList : styles.hiddenText}>
        {consultingList.map((item) => {
          return <li key={consultingList.indexOf(item)}>{item}</li>;
        })}
      </ul>
      <ul className={isSubscription ? styles.servicesList : styles.hiddenText}>
        {subscriptionList.map((item) => {
          return <li key={subscriptionList.indexOf(item)}>{item}</li>;
        })}
      </ul>
    </div>
  );
};

export default MainServicesLinks;
