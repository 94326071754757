import FormBook from "components/Main/FormBook/FormBook";
import checkbox from "../../images/icons/TrialCheckbox.svg";
import trialStar from "../../images/icons/TrialStar.svg";
import styles from "./trial.module.sass";
import { useTranslation } from "react-i18next";

const Trial = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.trial}>
        <h2 className={styles.h2}>
          {t('trial.title')} <span>{t('trial.subTitle')}</span>
        </h2>
        <div className={styles.trialCards}>
          <div className={styles.trialPrices}>
            <img
              src={trialStar}
              loading="eager"
              alt="star"
              className={styles.trialStar}
            />
            <h3 className={styles.h3}>100$</h3>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('trial.frstCard.trialPeriod')}</p>
            </div>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('trial.manual')}</p>
            </div>
            <div className={styles.trialResult}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>
                <span>{t('trial.result')}</span>
              </p>
            </div>
            <ul>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.frstCard.results.frstPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.frstCard.results.scndPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.frstCard.results.thdPoint')}</li>
              </div>
            </ul>
          </div>
          <div className={styles.trialPrices}>
            <img
              src={trialStar}
              loading="eager"
              alt="star"
              className={styles.trialStar}
            />
            <h3 className={styles.h3}>500$</h3>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('trial.scndCard.trialPeriod')}</p>
            </div>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('trial.manual')}</p>
            </div>
            <div className={styles.trialResult}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>
                <span>{t('trial.result')}</span>
              </p>
            </div>
            <ul>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.scndCard.results.frstPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.scndCard.results.scndPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.scndCard.results.thdPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.scndCard.results.frthPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.scndCard.results.ffthPoint')}</li>
              </div>
            </ul>
          </div>
          <div className={styles.trialPrices}>
            <img
              src={trialStar}
              loading="eager"
              alt="star"
              className={styles.trialStar}
            />
            <h3 className={styles.h3}>1500$</h3>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>{t('trial.thdCard.trialPeriod')}</p>
            </div>
            <div className={styles.trialPeriod}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>
                {t('trial.manual')},<br />
                {t('trial.automation')}
              </p>
            </div>
            <div className={styles.trialResult}>
              <img src={checkbox} loading="eager" alt="checkbox" />
              <p>
                <span>{t('trial.result')}</span>
              </p>
            </div>
            <ul>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.frstPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.scndPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.thdPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.frthPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.ffthPoint')}</li>
              </div>
              <div className={styles.point}>
                <span>*</span>
                <li>{t('trial.thdCard.results.sxthPoint')}</li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <FormBook />
    </>
  );
};

export default Trial;
