import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { googleCaptchaSiteKey } from "constants/config";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Modals from "components/modals/Modals";
import pages from "./pages/index";
import "./scss/globals.sass";

import { useTranslation } from "react-i18next";

function App () {

  const { t } = useTranslation();

return (
    <GoogleReCaptchaProvider reCaptchaKey={googleCaptchaSiteKey}>
  <BrowserRouter>
    <ScrollToTop />
    <Layout>
      <Switch>
        {pages.map((page) => (
          <Route
            path={page.path}
            exact
            component={page.component}
            key={page.name}
          />
        ))}
      </Switch>
    </Layout>
    <Modals />
    <CookieConsent
      style={{ background: "#252c33", textAlign: "left" }}
      buttonStyle={{
        color: "#4EB5FF",
        background: "#252c33",
      }}
      expires={365}
      enableDeclineButton
      buttonText={t('cookieConsent.agree')}
      declineButtonText={t('cookieConsent.decline')}
      declineButtonStyle={{ color: "#4EB5FF", background: "#252c33" }}
    >
      {t('cookieConsent.cookie')}{" "}
      <Link to="/privacy-policy" style={{ color: "#4EB5FF" }}>
        {t('cookieConsent.link')}.
      </Link>
    </CookieConsent>
  </BrowserRouter>
    </GoogleReCaptchaProvider>
  )  
};

export default App;
