import useMedia from "../../hooks/useMedia";
import ServicesNextArrow from "../../components/Services/ServicesArrows/ServicesNextArrow";
import ServicesPrevArrow from "../../components/Services/ServicesArrows/ServicesPrevArrow";
import ServicesSlogan from "../../components/Services/ServicesSlogan/ServicesSlogan";
import ServicesStrongPoints from "../../components/Services/ServicesStrongPoints/ServicesStrongPoints";
import ServicesTitle from "../../components/Services/ServicesTitle/ServicesTitle";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";

import automationlogo from "../../images/imgs/AutomationLogo.png";
import subscriptionlogo from "../../images/imgs/SubscriptionLogo.svg";
import nextArrow from "../../images/icons/ServicesArrowNext.svg";
import prevArrow from "../../images/icons/ServicesArrowPrev.svg";

import WeDoLine from "../../components/Services/WeDoLine/WeDoLine";
import ServicesTestingGuide from "../../components/Services/ServicesTestingGuide/ServicesTestingGuide";
import ServicesScroll from "../../components/Services/ServicesScroll/ServicesScroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import styles from "./styles/servicesPage.module.sass";
import { useTranslation } from "react-i18next";

const ManualTesting = () => {
  const { t, i18n } = useTranslation();
  const chooseUsBox = i18n.resolvedLanguage === 'ru' ? 'chooseUsBoxRu' : 'chooseUsBox';

  const { isMobile } = useMedia();

  const scrollData = t('servicesPage.manualTesting.scrollData', { returnObjects: true });

  return (
    <div>
      <div className={styles.servicesMainBox}>
        {!isMobile ? (
          <div>
            <ServicesNextArrow
              aboveArrText={t('servicesPage.manualTesting.servicesNextArrow')}
              arrowSrc={nextArrow}
              link="test-automation"
              imgSrc={automationlogo}
            />
          </div>
        ) : null}
        <div
          className={`${styles.servicesMain} ${styles.servicesMainManualBg}`}
        >
          <div className={styles.servicesTitle}>
            <ServicesTitle
              fTitle={t('servicesPage.manualTesting.servicesTitle.fTitle')}
              sTitle={t('servicesPage.manualTesting.servicesTitle.sTitle')}
              thTitle={t('servicesPage.manualTesting.servicesTitle.thTitle')}
              ftTitle={t('servicesPage.manualTesting.servicesTitle.ftTitle')}
            />
          </div>
          <div className={styles.servicesSlogan}>
            <ServicesSlogan slogan={t('servicesPage.manualTesting.servicesSlogan')} />
          </div>
        </div>
        {!isMobile ? (
          <ServicesPrevArrow
            aboveArrText={t('servicesPage.manualTesting.servicesPrevArrow')}
            arrowSrc={prevArrow}
            link="qa-subscription"
            imgSrc={subscriptionlogo}
          />
        ) : null}
      </div>
      <WeDoLine />
      <ServicesScroll
        items={scrollData}
        defaultItem={scrollData[0]}
        color="#769DFF"
        title={t('servicesPage.manualTesting.servicesScrollTitle')}
      />
      <div className={styles.servicesPoints}>
        <ServicesStrongPoints />
        {!isMobile ? (
          <div className={styles.benefitsManual}>
            <p>
              {t('servicesPage.manualTesting.benefitsManual.frstPoint')}
            </p>
            <p>
              {t('servicesPage.manualTesting.benefitsManual.scdPoint')}
            </p>
            <p>
              {t('servicesPage.manualTesting.benefitsManual.thdPoint')}
            </p>
            <p>
              {t('servicesPage.manualTesting.benefitsManual.frthPoint')}
            </p>
          </div>
        ) : (
          <div className={styles.benefitsSlider}>
            <div className={styles.benefitsContainer}>
              <Swiper
                speed={1000}
                parallax={true}
                keyboard={{
                  enabled: true,
                }}
                pagination={{
                  clickable: true,
                  bulletActiveClass: styles.processPaginationActive,
                }}
                slidesPerView={1}
                spaceBetween={0}
                scrollbar={{
                  hide: false,
                  draggable: true,
                }}
                autoplay={{
                  waitForTransition: true,
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Keyboard, Parallax]}
                className={styles.swiper}
              >
                <div
                  slot="container-start"
                  className={styles.parallaxBgManual}
                  data-swiper-parallax="-61%"
                ></div>
                <SwiperSlide className={styles.slide}>
                  <p>
                    {t('servicesPage.manualTesting.benefitsManual.frstPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.text}>
                    {t('servicesPage.manualTesting.benefitsManual.scdPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.textResources}>
                    {t('servicesPage.manualTesting.benefitsManual.thdPoint')}
                  </p>
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <p className={styles.text}>
                    {t('servicesPage.manualTesting.benefitsManual.frthPoint')}
                  </p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        )}
      </div>
      <div className={styles[chooseUsBox]}>
        <h2 className={styles.chooseTitle}>
          <span>{t('servicesPage.manualTesting.chooseTitle.title')} </span>{t('servicesPage.manualTesting.chooseTitle.subTitle')}
        </h2>
        <div>
          <TwoArrowBtn
            number="01"
            text={t('servicesPage.manualTesting.chooseUsBox.twoArrowBtn01')}
            arrColor="#769DFF"
            borderColor="#769DFF"
            botBorderColor="#769DFF"
          />
        </div>
        <TwoArrowBtn
          number="02"
          text={t('servicesPage.manualTesting.chooseUsBox.twoArrowBtn02')}
          arrColor="#769DFF"
          borderColor="#769DFF"
          botBorderColor="#769DFF"
        />
        <div>
          <TwoArrowBtn
            number="03"
            text={t('servicesPage.manualTesting.chooseUsBox.twoArrowBtn03')}
            arrColor="#769DFF"
            borderColor="#769DFF"
            botBorderColor="#769DFF"
          />
        </div>
        <TwoArrowBtn
          number="04"
          text={t('servicesPage.manualTesting.chooseUsBox.twoArrowBtn04')}
          arrColor="#769DFF"
          borderColor="#769DFF"
          botBorderColor="#769DFF"
        />
      </div>
      <ServicesTestingGuide />
    </div>
  );
};

export default ManualTesting;
