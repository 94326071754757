import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "features/modal-slice";
import postReducer from "features/post-slice";
import categoryReducer from "features/categories-slice"
import mediaReducer from "features/media-slice"
import tagsReducer from "features/tags-slice"

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    posts: postReducer,
    categories: categoryReducer,
    media: mediaReducer,
    tags: tagsReducer
  },
});
