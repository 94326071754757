import { Link } from "react-router-dom";
import styles from "./styles/header.module.sass";
import logo from "../../images/icons/logo.svg";

const Header = () => {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.headerLink}>
        <img
          className={styles.logo}
          src={logo}
          width="32"
          height="32"
          alt="main"
        />
      </Link>
    </header>
  );
};

export default Header;
