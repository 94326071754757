import BackButton from "../../components/UI/button/BackButton";
import JobOfferForm from "./components/JobOfferForm";
import styles from "./styles/job-offer.module.sass";
import { useTranslation } from "react-i18next";

const FullStackDeveloper = () => {

  const { t } = useTranslation();

  return (
    <div className={styles.job}>
      <div className={styles.boxjob}>
        <header>
          <div className={styles.backbuton}>
            <BackButton title={t('jobOffer.backButton')} />
          </div>
          <h1 className={styles.h1}>{t('career.careerJobsContent.scndOffer')}</h1>
          <p>{t('career.careerJobsContent.time')}</p>
        </header>
        <main>
          <div className={styles.requiries}>
            <ul className={styles.location}>
              <li>{t('jobOffer.locale')}</li>
              <li>{t('career.careerJobsContent.location')}</li>
            </ul>
            <ul className={styles.experience}>
              <li>{t('jobOffer.practice')}</li>
              <li>{t('jobOffer.experience')}</li>
            </ul>
            <ul className={styles.skills}>
              <li>{t('jobOffer.titleSkills')}</li>
              <li>Vue.js</li>
              <li>Node.js</li>
              <li>AWS</li>
              <li>MongoDB</li>
              <li>Postgres</li>
              <li>Express.js</li>
              <li>Kubernetes</li>
              <li>Docker</li>
              <li>Gitlab</li>
            </ul>
          </div>
          <div className={styles.cherishdev}>
            <p>
              {t('jobOffer.text')}
            </p>
            <div className={styles.list}>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.missions.frstPoint')}</p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>
                  {t('jobOffer.missions.scdPoint')}
                </p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>
                  {t('jobOffer.missions.thdPoint')}
                </p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.missions.frthPoint')}</p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.missions.ffthPoint')}</p>
              </div>
            </div>

            <h2 className={styles.h2}>
              {t('jobOffer.title')}
            </h2>
            <div className={styles.list}>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.goodFit.frstPoint')}</p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.goodFit.scdPoint')}</p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>
                  {t('jobOffer.fullStackDeveloper.thdPoint')}
                </p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>{t('jobOffer.goodFit.frthPoint')}</p>
              </div>
              <div className={styles.part}>
                <p className={styles.listStyle}>*</p>
                <p>
                  {t('jobOffer.goodFit.ffthPoint')}
                </p>
              </div>
            </div>
            <p>
              {t('jobOffer.conclusion')}
            </p>
          </div>
        </main>
        <div className={styles.form}>
          <div className={styles.formTitle}>
            <h3 className={styles.h3}>{t('jobOffer.form.title')}</h3>
            <h2 className={styles.h2}>{t('jobOffer.form.subTitle')}</h2>
          </div>
          <JobOfferForm />
        </div>
      </div>
    </div>
  );
};

export default FullStackDeveloper;
