import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";
import { ContactUsModal } from "components/modals/Modals";
import { useTranslation } from "react-i18next";
import arrow from "../../../images/smallArrow.svg";
import styles from "../button/styles/orderButton.module.sass";

const OrderButton = () => {
  const {t, i18n} = useTranslation();

  const orderButton = i18n.resolvedLanguage === 'ru' ? 'orderButtonRu' : 'orderButton';

  const dispatch = useDispatch();
  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };
  return (
    <button className={styles[orderButton]} onClick={openContactUsModal}>
      {t('servicesPage.qaSubscription.subscriptionCards.button')}
      <img src={arrow} loading="eager" alt="arrow"/>
    </button> 
  );
};

export default OrderButton;
