import { useClipboard } from "use-clipboard-copy";
import { animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import { email as emailRule } from "utils/validationRules";
import useValidateForm from "hooks/useValidateForm";
import useMedia from "hooks/useMedia";
import { sendFollow } from "services/forms";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";
import { ThanksForSubscriptionFooter } from "components/modals/Modals";
import { ErrorModal } from "components/modals/Modals";

import SubcsribeButton from "components/UI/button/SubscribeButton";
import AccordionMobile from "../Accordion/AccordionMobile";
import TextField from "components/UI/formControls/TextField";
import Slogan from "../UI/slogan/Slogan";
import logo from "../../images/icons/logo.svg";
import Links from "../Links/Links";

import styles from "./styles/footer.module.sass";
import Checkbox from "components/UI/formControls/Checkbox";
import { useTranslation } from "react-i18next";

const formName = "footerForm";

const Footer = () => {
  const [isPrivacyChecked, setPrivacyChecked] = useState(true);
  const [isSubscribeChecked, setSubscribeChecked] = useState(true);

  const { t } = useTranslation();

  const aboutData = t('footer.aboutData', { returnObjects: true });

  const servicesData = t('menu', { returnObjects: true });

  const { isMobile } = useMedia();

  const dispatch = useDispatch();

  const openThanksForSubscriptionFooter = () => {
    dispatch(openModal(ThanksForSubscriptionFooter));
  };
  const openError = () => {
    dispatch(openModal(ErrorModal));
  };

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = useValidateForm(formName);

  let errors = {
    email: false,
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("isPrivacyChecked", isPrivacyChecked);
    formData.append("isSubscribeChecked", isSubscribeChecked);
    setIsLoading(true);

    sendFollow(formData)
      .then(() => {
        openThanksForSubscriptionFooter();
      })
      .catch(() => {
        openError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPressSend = (e) => {
    e.preventDefault();
    validateForm();
    if (Object.values(errors).every((e) => e === false)) {
      sendForm();
    } else {
      console.log("Form is not valid");
    }
  };

  const clipboardFirst = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });
  const clipboardSecond = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerBox}>
        <div className={styles.footerTitle}>
          <div className={styles.title}>
            <Link
              to="/"
              className={styles.headerLink}
              onClick={scrollToTop}
              duration={500}
            >
              <img className={styles.logo} src={logo} alt="main" />
            </Link>
            <h2 className={styles.h2}> CHERISH DEV</h2>
          </div>
          <Slogan />
        </div>
        <div className={styles.footerMenu}>
          {isMobile ? (
            <div className={styles.boxMenuMobile}>
              <AccordionMobile title={t('footer.about')}>
                <ul>
                {aboutData.map((data) => {
                    return (
                      <li key={data.href}>
                        <Link to={data.href} onClick={scrollToTop}>
                          {data.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </AccordionMobile>
              <AccordionMobile title={t('footer.services')}>
                <ul>
                  <li>
                    <Link onClick={scrollToTop} to="/services/test-automation">
                      {t('services.automation')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/services/manual-testing">
                    {t('services.manual')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/services/consulting&audit">
                    {t('services.consulting')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/services/qa-subscription">
                    {t('services.subscription')}
                    </Link>
                  </li>
                </ul>
              </AccordionMobile>
              <AccordionMobile title={t('footer.get')}>
                <ul className={styles.menuGetInTouchMobile}>
                  <li>
                    {t('footer.basicCountry')}{" "}
                    <span
                      ref={clipboardFirst.target}
                      onClick={() => {
                        clipboardFirst.copy(
                          clipboardFirst.target.current.innerText
                        );
                      }}
                    >
                      {t('footer.basicPhone')}
                    </span>
                  </li>
                  <li>
                    {t('footer.additionalCountry')}{" "}
                    <span
                      ref={clipboardSecond.target}
                      onClick={() => {
                        clipboardSecond.copy(
                          clipboardSecond.target.current.innerText
                        );
                      }}
                    >
                      {t('footer.additionalPhone')}
                    </span>
                  </li>
                </ul>
              </AccordionMobile>
            </div>
          ) : (
            <div className={styles.boxMenu}>
              <div className={styles.menuAboutUs}>
                <h3 className={styles.h3}>{t('footer.about')}</h3>
                <ul>
                  {aboutData.map((data) => {
                    return (
                      <li key={data.href}>
                        <Link to={data.href} onClick={scrollToTop}>
                          {data.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.menuServices}>
                <h3 className={styles.h3}>{t('footer.services')}</h3>
                <ul>
                  {servicesData.map((data) => {
                    return (
                      <li key={data.href}>
                        <Link to={data.href} onClick={scrollToTop}>
                          {data.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.menuGetInTouch}>
                <h3 className={styles.h3}>{t('footer.get')}</h3>
                <ul>
                  <li>
                    {t('footer.basicCountry')}
                    <span
                      ref={clipboardFirst.target}
                      onClick={() => {
                        clipboardFirst.copy(
                          clipboardFirst.target.current.innerText
                        );
                      }}
                    >
                      {t('footer.basicPhone')}
                    </span>
                  </li>
                  <li>
                    {t('footer.additionalCountry')}
                    <span
                      ref={clipboardSecond.target}
                      onClick={() => {
                        clipboardSecond.copy(
                          clipboardSecond.target.current.innerText
                        );
                      }}
                    >
                      {t('footer.additionalPhone')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={styles.footerFeedback}>
          <form name={formName} className={styles.footerForm}>
            <label>{t('footer.subscribeArt')}</label>
            <TextField
              className={styles.footerInput}
              placeholder="my_email@gmail.com"
              value={email}
              rules={{ email: emailRule }}
              onChange={(value, error) => {
                errors = { ...errors, email: error };
                setEmail(value);
              }}
            />
            <SubcsribeButton
              onClick={onPressSend}
              isLoading={isLoading}
              disabled={!(isPrivacyChecked && isSubscribeChecked)}
            />
            <Checkbox
              name="privacy"
              value={isPrivacyChecked}
              onChange={() => setPrivacyChecked(!isPrivacyChecked)}
            >
              <p>
                {t('footer.sendingText')}
                <Link to="/privacy-policy">{t('footer.privacyLink')}</Link>
              </p>
            </Checkbox>
            <Checkbox
              name="footerSubscribe"
              value={isSubscribeChecked}
              onChange={() => setSubscribeChecked(!isSubscribeChecked)}
            >
              <p>
                <span>{t('footer.subscribeNews')}</span> {t('footer.unsubscribe')}
              </p>
            </Checkbox>
          </form>
          <div className={styles.footerContacts}>
            <Links className={styles.footerLinks} />
            <h2 className={styles.h2}>
              <a href="mailto:contacts@cherishdev.com">
                contacts@cherishdev.com
              </a>
            </h2>
            <p>{t('footer.address')}</p>
          </div>
        </div>
        <div className={styles.footerDocuments}>
          <ul>
            <li>
              <Link to="/privacy-policy">{t('privacyLink')}</Link>
            </li>
            <li>
              <Link to="/terms&conditions">{t('footer.terms')}</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
