import VisitButton from "../../UI/button/VisitButton";
import Case from "../../Cases/Case";
import styles from "../styles/record.module.sass";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import free2move from "../../../images/cases/free2move.svg";
import free2moveHover from "../../../images/cases/free2moveHover.svg";
import godoHover from "../../../images/cases/godoHover.svg";
import godo from "../../../images/cases/godo.svg";
import crexi from "../../../images/cases/crexi.svg";
import crexiHover from "../../../images/cases/crexiHover.svg";
import { useTranslation } from "react-i18next";

const RecordOfSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.recordBox}>
      <div className={styles.recordTitle}>
        <h3 className={styles.recordTitleText}>
          {t('main.recordOfSuccess.title')} <span>{t('main.recordOfSuccess.subTitle')}</span>
        </h3>
        <div className={styles.recordCasesBtn}>
          <VisitButton title={t('main.recordOfSuccess.visitButton')} href="/case-studies" />
        </div>
      </div>
      <div className={styles.recordCasesBox}>
        <div className={styles.recordCasesContent}>
          <Swiper
            speed={1000}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              clickable: true,
              bulletActiveClass: styles.processPaginationActive,
            }}
            slidesPerView={1}
            spaceBetween={0}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            autoplay={{
              waitForTransition: true,
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Keyboard]}
            className={styles.swiper}
          >
            <SwiperSlide className={styles.slide}>
              <Case
                title="free2move"
                industry={t('main.recordOfSuccess.free2move.industry')}
                duration={t('main.recordOfSuccess.free2move.duration')}
                staff="7+"
                engagement={t('main.recordOfSuccess.free2move.engagement')}
                platforms="Android iOs Web"
                href="/case-studies/free2move"
                img={free2move}
                hoverImg={free2moveHover}
              />
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <Case
                title="godo"
                industry={t('main.recordOfSuccess.godo.industry')}
                duration={t('main.recordOfSuccess.godo.duration')}
                staff="4+"
                engagement={t('main.recordOfSuccess.godo.engagement')}
                platforms="Web"
                href="/case-studies/godo"
                img={godo}
                hoverImg={godoHover}
              />
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <Case
                title="crexi"
                industry={t('main.recordOfSuccess.crexi.industry')}
                duration={t('main.recordOfSuccess.crexi.duration')}
                staff="5+"
                engagement={t('main.recordOfSuccess.crexi.engagement')}
                platforms="Web(inc. API)"
                href="/case-studies/crexi"
                img={crexi}
                hoverImg={crexiHover}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RecordOfSuccess;
