import { openModal } from "features/modal-slice";
import { RecaptchaErrorModal, ErrorModal } from "components/modals/Modals";

const recaptchaErrorCodes = [
                    'timeout-or-duplicate',
                    'missing-input-secret',
                    'invalid-input-secret',
                    'missing-input-response',
                    'bad-request'
                    ]
export const openErrorModal = (err, dispatch) => {
  const res = err.response?.data
  if (res?.success === false && recaptchaErrorCodes.includes(res?.["error-codes"])) {
    dispatch(openModal(RecaptchaErrorModal));
  } else {
    dispatch(openModal(ErrorModal));
  }
}
