import { useDispatch } from "react-redux";
import { openModal } from "features/modal-slice";
import { ContactUsModal } from "components/modals/Modals";
import rely from "../../images/cases/Rely.svg";
import styles from "./styles/rely.module.sass";
import { useTranslation } from "react-i18next";

const Rely = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  return (
    <div className={styles.relyBox}>
      <div className={styles.relyContentBox}>
        <img src={rely} alt="rely" />
        <div className={styles.relyContent}>
          <div className={styles.relyTitle}>
            <h2 className={styles.h2} onClick={openContactUsModal}>
              {t('cases.rely.title')}
            </h2>
            <svg
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
                fill="#FFF"
              />
            </svg>
          </div>
          <p className={styles.relyText}>{t('cases.rely.text')}</p>
        </div>
      </div>
    </div>
  );
};

export default Rely;
