import { useDispatch, useSelector } from "react-redux";
import useOutsideClick from "hooks/useOutsideClick";
import { closeModal } from "features/modal-slice";
import useMedia from "../../../hooks/useMedia";

import Contacts from "../../Contacts/Contacts";
import FollowModalForm from "./components";
import { ReactComponent as CloseButton } from "../../../images/icons/closeButton.svg";
import styles from "./followModal.module.sass";
import { useTranslation } from "react-i18next";

const FollowModal = ({ name }) => {
  const { t } = useTranslation();

  const { isMobile } = useMedia();
  const opened = useSelector((state) => state.modal.modals[name]);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal(name));
  };

  const ref = useOutsideClick(close);

  return (
    <div
      className={
        opened ? `${styles.modalActive} ${styles.modal}` : styles.modal
      }
      ref={ref}
    >
      <button className={styles.buttonClose} onClick={close}>
        <CloseButton/>
      </button>
      <div className={styles.modalText}>
        <h1>
           {t('modals.followModal.title')}<span>{t('modals.followModal.subTitle')}</span>
        </h1>
        <p>{t('modals.followModal.modalBody')}</p>
      </div>
      <div className={styles.modalBlock}>
        <div className={styles.modalContacts}>
          {!isMobile ? <Contacts /> : null}
        </div>
        <FollowModalForm onClose={close} />
      </div>
    </div>
  );
};

export default FollowModal;
