// returens list of pages for React Router
import Error404 from "./404/index";
import Career from "./career/index";
import CaseStudies from "./case-studies/index";
import Crexi from "./case-studies/crexi";
import Free2move from "./case-studies/free2move";
import Godo from "./case-studies/godo";
import TechMonet from "./case-studies/techMonet";
import Company from "./company";
import Contacts from "./contacts/index";
import CookiePolicy from "./documents/cookie-policy";
import Faq from "./faq/index";
import Home from "./home/index";
import FullStackDeveloper from "./job-offer/FullStack";
import BackEndDeveloper from "./job-offer/BackEnd";
import PrivacyPolicy from "./documents/privacy-policy";
import ConsultingAndAudit from "./services/consulting&audit";
import ManualTesting from "./services/manual-testing";
import TestAutomation from "./services/test-automation";
import QASubscription from "./services/qa-subscription";
import TermsAndConditions from "./documents/terms&conditions";
import Blog from "./blog";
import Post from "./blog/post";
import Trial from "./trial";
import pathMapper from "./paths-mapper";

const pages = [
  {
    name: "career",
    meta: {},
    path: pathMapper.career,
    component: Career,
  },
  {
    name: "caseStudies",
    meta: {},
    path: pathMapper.caseStudies,
    component: CaseStudies,
  },
  {
    name: "crexi",
    meta: {},
    path: pathMapper.crexi,
    component: Crexi,
  },
  {
    name: "free2move",
    meta: {},
    path: pathMapper.free2move,
    component: Free2move,
  },
  {
    name: "godo",
    meta: {},
    path: pathMapper.godo,
    component: Godo,
  },
  {
    name: "TechMonet",
    meta: {},
    path: pathMapper.techMonet,
    component: TechMonet,
  },
  {
    name: "company",
    meta: {},
    path: pathMapper.company,
    component: Company,
  },
  {
    name: "contacts",
    meta: {},
    path: pathMapper.contacts,
    component: Contacts,
  },
  {
    name: "cookiePolicy",
    meta: {},
    path: pathMapper.cookiePolicy,
    component: CookiePolicy,
  },
  {
    name: "faq",
    meta: {},
    path: pathMapper.faq,
    component: Faq,
  },
  {
    name: "home",
    meta: {},
    path: pathMapper.home,
    component: Home,
  },
  {
    name: "FullStackDeveloper",
    meta: {},
    path: pathMapper.fullStackDeveloper,
    component: FullStackDeveloper,
  },
  {
    name: "BackEndDeveloper",
    meta: {},
    path: pathMapper.backEndDeveloper,
    component: BackEndDeveloper,
  },
  {
    name: "privacyPolicy",
    meta: {},
    path: pathMapper.privacyPolicy,
    component: PrivacyPolicy,
  },
  {
    name: "consultingAndAudit",
    meta: {},
    path: pathMapper.consultingAndAudit,
    component: ConsultingAndAudit,
  },
  {
    name: "manualTesting",
    meta: {},
    path: pathMapper.manualTesting,
    component: ManualTesting,
  },
  {
    name: "testAutomation",
    meta: {},
    path: pathMapper.testAutomation,
    component: TestAutomation,
  },
  {
    name: "QASubscription",
    meta: {},
    path: pathMapper.QASubscription,
    component: QASubscription,
  },
  {
    name: "termsAndConditions",
    meta: {},
    path: pathMapper.termsAndConditions,
    component: TermsAndConditions,
  },
  {
    name: "blog",
    meta: {},
    path: pathMapper.blog,
    component: Blog,
  },
  {
    name: "blog",
    meta: {},
    path: pathMapper.blogCategory,
    component: Blog,
  },
  { name: "post", meta: {}, path: pathMapper.post, component: Post },
  { name: "trial", meta: {}, path: pathMapper.trial, component: Trial },
  {
    name: "404",
    meta: {},
    path: "*",
    component: Error404,
  },
];

export default pages;
