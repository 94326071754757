import { useDispatch } from "react-redux";

import { openModal } from "features/modal-slice";
import useMedia from "../../hooks/useMedia";
import { ContactUsModal } from "components/modals/Modals";
import PrimaryButton from "../UI/button/PrimaryButton";
import BackButton from "../UI/button/BackButton";
import CustomArrow from "../UI/arrow/CutomArrow";

import styles from "./styles/mainCase.module.sass";
import { useTranslation } from "react-i18next";

const MainCase = ({
  title,
  caseImg,
  requirements,
  extension,
  nextCase,
  prevCase,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isDesktop } = useMedia();

  const openContactUsModal = () => {
    dispatch(openModal(ContactUsModal));
  };

  return (
    <>
      <div className={styles.mainCaseBox}>
        <div className={styles.mainCaseBack}>
          <BackButton title={t('cases.mainCase.backButton')} href="/case-studies" />
        </div>
        <div className={styles.mainCaseContent}>
          <h1 className={styles.h1}>{title}</h1>
          <div className={styles.mainCaseImg}>
            <img src={caseImg} loading="eager" alt="case" />
          </div>
          <div className={styles.mainCaseBtn} onClick={openContactUsModal}>
            <PrimaryButton text={t('cases.mainCase.primaryButton')} />
          </div>
          <div className={styles.mainCaseReq}>
            <h2 className={styles.h2}>
              {t('cases.mainCase.title')}
              <br />
              {t('cases.mainCase.subTitle')}
            </h2>
            <div className={styles.reqNotes}>
              <p className={styles.extension}>{extension}</p>
              <p>{requirements}</p>
            </div>
          </div>
          {isDesktop ? (
            <div>
              <div className={styles.nextCaseArr}>
                <CustomArrow
                  aboveArrText={t('cases.mainCase.rightArrow')}
                  underArrText={nextCase}
                  direction="right"
                  href={`/case-studies/${nextCase}`}
                />
              </div>

              <div className={styles.prevCaseArr}>
                <CustomArrow
                  aboveArrText={t('cases.mainCase.leftArrow')}
                  underArrText={prevCase}
                  direction="left"
                  href={`/case-studies/${prevCase}`}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MainCase;
