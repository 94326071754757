import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import AboutProject from "../../components/Cases/AboutProject";
import IndustriesTitle from "components/Cases/IndustriesTitle";
import IndustriesLine from "../../components/Cases/IndustriesLine";
import MainCase from "../../components/Cases/MainCase";
import Rely from "../../components/Cases/Rely";
import Shares from "../../components/Cases/Shares";
import TaskToPerform from "../../components/Cases/TaskToPerform";
import TwoArrowBtn from "../../components/UI/twoArrowBtn/TwoArrowBtn";
import techMonetBg from "../../images/cases/techMonetBg.svg";
import techMonetTools from "../../images/cases/techMonetTools.svg";
import { useTranslation } from "react-i18next";
import styles from "./styles/cases.module.sass";

const TechMonet = () => {

  const {t, i18n} = useTranslation();

  const sharesLinks = i18n.resolvedLanguage === 'ru' ? 'sharesLinksRu' : 'sharesLinks';

  const shareLink = "cherishdev.com/case-studies/techMonet";

  const goalsData = t('cases.techMonet.goalsData', { returnObjects: true });

  const remediesData = t('cases.techMonet.remediesData', { returnObjects: true });

  const arrowBtnData = t('cases.techMonet.arrowBtnData', { returnObjects: true });

  return (
    <div className={styles.singleCaseBox}>
      <MainCase
        title="techMonet"
        caseImg={techMonetBg}
        requirements={t('cases.techMonet.requirements')}
        nextCase="free2move"
        prevCase="crexi"
      />
      <AboutProject
        companyProfile={t('cases.techMonet.companyProfile')}
        industry={t('cases.techMonet.industry')}
        duration={t('cases.techMonet.duration')}
        staff="6+"
        engagement={t('cases.techMonet.engagement')}
        platforms="Web"
        goals={goalsData}
        imgSrc={techMonetTools}
      />
      <div className={styles.note}>
        <span>*</span><p>{t('cases.techMonet.note')}</p>
      </div>
      <TaskToPerform
        remediesData={remediesData}
      />
      <div className={styles.free2moveOutcomesBox}>
        <div className={styles.free2moveOutcomesBoxContent}>
          <h2 className={styles.free2moveOutcomesTitle}>{t('cases.free2Move.outcomesTitle')}</h2>
          {arrowBtnData.map((data) => {
            return (
              <TwoArrowBtn
                key={data.number}
                number={data.number}
                text={data.text}
              />
            );
          })}
        </div>
      </div>
      <div className={styles[sharesLinks]}>
        <p>
          {t('cases.techMonet.sharesLink')}<span>(1k+)</span>
        </p>
        <ul className={styles.sharesList}>
          <li>
            <LinkedinShareButton url={shareLink}>linkin</LinkedinShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={shareLink}
              title={t('cases.techMonet.twitterShareLink')}
            >
              twtr
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton url={shareLink}>fb</FacebookShareButton>
          </li>
        </ul>
      </div>
      <Shares prevArrowCaseTitle="crexi" nextArrowCaseTitle="free2move" />
      <div className={styles.casesIndustries}>
        <IndustriesTitle />
        <div className={styles.industriesLine}>
          <IndustriesLine />
        </div>
      </div>
      <Rely />
    </div>
  );
};

export default TechMonet;
