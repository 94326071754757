import CustomArrow from "../UI/arrow/CutomArrow";
import styles from "./styles/shares.module.sass";
import { useTranslation } from "react-i18next";

const Shares = ({
  prevArrowCaseTitle = "godo" || "crexi" || "free2move",
  nextArrowCaseTitle = "godo" || "crexi" || "free2move",
}) => {

  const { t } = useTranslation();

  return (
    <div className={styles.sharesBox}>
      <div className={styles.sharesBoxContent}>
        <div className={styles.sharesArrows}>
          <div className={styles.sharesCircle}></div>
          <div className={styles.sharesArr}>
            <CustomArrow
              href={`/case-studies/${prevArrowCaseTitle}`}
              aboveArrText={t('cases.mainCase.leftArrow')}
              direction="left"
              underArrText={prevArrowCaseTitle}
            />
          </div>
          <hr className={styles.sharesHr} />
          <div className={styles.sharesArr}>
            <CustomArrow
              href={`/case-studies/${nextArrowCaseTitle}`}
              aboveArrText={t('cases.mainCase.rightArrow')}
              direction="right"
              underArrText={nextArrowCaseTitle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shares;
