import { useClipboard } from "use-clipboard-copy";
import PageSingleTitle from "../../components/UI/pageTitle/PageSingleTitle";
import styles from "./contacts.module.sass";
import ContactsForm from "./components/ContactsForm";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();

  const clipboardFirst = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });
  const clipboardSecond = useClipboard({
    onSuccess() {
      alert("Copied to clipboard");
    },
  });
  return (
    <main className={styles.contactsBox}>
      <div className={styles.contactBoxTitle}>
        <PageSingleTitle title={t('contacts.title')} />
      </div>
      <div className={styles.contactsMainBox}>
        <div className={styles.contactsContent}>
          <div className={styles.contactsMainContacts}>
            <ul>
              <li
                ref={clipboardFirst.target}
                onClick={() => {
                  clipboardFirst.copy(clipboardFirst.target.current.innerText);
                }}
              >
                  {t('footer.basicCountry')} <span>{t('footer.basicPhone')}</span>
              </li>
              <li
                ref={clipboardSecond.target}
                onClick={() => {
                  clipboardSecond.copy(
                    clipboardSecond.target.current.innerText
                  );
                }}
              >
                  {t('footer.additionalCountry')} <span>{t('footer.additionalPhone')}</span>
              </li>
            </ul>
            <p>{t('footer.address')}</p>
          </div>
        </div>
        <div className={styles.contactsEmail}>
          <a href="mailto:contacts@cherishdev.com">contacts@cherishdev.com</a>
        </div>
      </div>
      <div className={styles.contactsSubContent}>
        <ul>
          <li>{t('contacts.numberKRS')}</li>
          <li>{t('contacts.NIP')}</li>
        </ul>
      </div>
      <div className={styles.underContentCaption}>{t('contacts.contentCaption')}</div>
      <div className={styles.contactsFormContainer}>
        <ContactsForm />
      </div>
    </main>
  );
};

export default Contacts;
