import useMedia from "../../hooks/useMedia";
import styles from "./styles/industriesTitle.module.sass";
import { useTranslation } from "react-i18next";

const IndustriesTitle = () => {
  const { t } = useTranslation();

  const { isDesktop } = useMedia();

  return (
    <div>
      <h2 className={styles.casesIndustriesTitle}>
          {t('cases.industriesTitle.title')}<span>{t('cases.industriesTitle.subTitle')}</span>
        {isDesktop && (
          <p className={styles.casesIndustriesTitle}>
              {t('cases.industriesTitle.title')}<span>{t('cases.industriesTitle.subTitle')}</span>{t('cases.industriesTitle.title')}
            <span>{t('cases.industriesTitle.subTitle')}</span> {t('cases.industriesTitle.title')}<span>{t('cases.industriesTitle.subTitle')}</span>
          </p>
        )}
      </h2>
    </div>
  );
};

export default IndustriesTitle;
