export default function useValidateForm (formName) {
    return () => {
        const controls = formName && Array.from(document.forms[formName]);

        formName && controls.forEach(c => {
            c.focus();
            c.blur();
        });
    };
};
