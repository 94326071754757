import arrow from "../../../images/icons/Vector.svg";
import styles from "./styles/card.module.sass";
import { useTranslation } from "react-i18next";

const Card = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.card}>
      <img src={arrow} alt="arrow" loading="eager" />
      <div className={styles.cardBox}>
        <p>
          <span>{t('cardBox')}</span>
          {text}
        </p>
      </div>
    </div>
  );
};

export default Card;
