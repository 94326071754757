import { useDispatch, useSelector } from "react-redux";
import useOutsideClick from "hooks/useOutsideClick";
import { closeModal } from "features/modal-slice";
import useMedia from "../../../hooks/useMedia";
import Contacts from "../../Contacts/Contacts";
import ContactUsModalForm from "./components/ContactUsModalForm";
import styles from "./styles/contact_us.module.sass";
import { useTranslation } from "react-i18next";

const ContactUsModal = ({ name }) => {
  const { t } = useTranslation();

  const { isMobile } = useMedia();
  const opened = useSelector((state) => state.modal.modals[name]);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal(name));
  };

  const ref = useOutsideClick(close);

  return (
    <div
      className={
        opened ? `${styles.modalActive} ${styles.modalBox}` : styles.modalBox
      }
      ref={ref}
    >
      <button className={styles.buttonClose} onClick={close}>
        <svg viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            transform="matrix(0.707107 -0.707107 0.707083 0.707131 0 1.99805)"
            fill="#687380"
          />
          <rect
            transform="matrix(0.707107 0.707107 -0.707083 0.707131 56.5771 1.00781)"
            fill="#687380"
          />
        </svg>
      </button>
      <h1>
        {t('modals.contactUsModal.title')} <span>{t('modals.contactUsModal.subTitle')}</span>
      </h1>
      <div className={styles.modalBlock}>
        {!isMobile ? <Contacts /> : null}
        <div className={styles.contactsForm}>
          <ContactUsModalForm onClose={close} />
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
