import { Link } from "react-router-dom";
import DocText from "../../components/Docs/DocText";
import DocTitle from "../../components/Docs/DocTitle";
import DocFooter from "../../components/Docs/DocFooter";
import styles from "./styles/documents.module.sass";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const {t} = useTranslation();

  return (
    <main className={styles.main}>
      <div className={styles.mainBox}>
        <DocTitle title={t('privacyPolicy.docTitle')} />
        <DocText />
        <h3 className={styles.h3}>{t('privacyPolicy.typesOfData.title')}</h3>
        <p>
          {t('privacyPolicy.typesOfData.01Point')}
        </p>
        <p>
          {t('privacyPolicy.typesOfData.02Point')}
        </p>
        <p>
          {t('privacyPolicy.typesOfData.03Point')}
        </p>
        <h3 className={styles.h3}>{t('privacyPolicy.modeTitle')}</h3>
        <h4 className={styles.h4}>{t('privacyPolicy.methods.title')}</h4>
        <p>
          {t('privacyPolicy.methods.01Point')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.legalBasic.title')}</h4>
        <p>
          {t('privacyPolicy.legalBasic.01Point')}
        </p>
        <ul>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.legalBasic.list.01Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.legalBasic.list.02Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.legalBasic.list.03Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.legalBasic.list.04Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.legalBasic.list.05Li')}
            </li>
          </div>
        </ul>
        <p>
          {t('privacyPolicy.legalBasic.02Point')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.place.title')}</h4>
        <p>
          {t('privacyPolicy.place.01Point')}
        </p>
        <p>
          {t('privacyPolicy.place.02Point')}
        </p>
        <p>
          {t('privacyPolicy.place.03Point')}
        </p>
        <p>
          {t('privacyPolicy.place.04Point')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.retention.title')}</h4>
        <p>
          {t('privacyPolicy.retention.01Point')}
        </p>
        <p>{t('privacyPolicy.retention.02Point')}</p>
        <ul>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.retention.list.01Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.retention.list.02Li')}
            </li>
          </div>
        </ul>
        <p>
          {t('privacyPolicy.retention.03Point')}
        </p>
        <p>
          {t('privacyPolicy.retention.04Point')}
        </p>
        <h2 className={styles.h2}>{t('privacyPolicy.rightsOfUsers.title')}</h2>
        <p>
          {t('privacyPolicy.rightsOfUsers.01Point')}
        </p>
        <h3 className={styles.partTitle}>
          {t('privacyPolicy.particular.title')}
        </h3>
        <ul>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>{t('privacyPolicy.particular.consentTitle')}</h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.01Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>{t('privacyPolicy.particular.objectTitle')}</h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.02Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>{t('privacyPolicy.particular.accessTitle')}</h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.03Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>{t('privacyPolicy.particular.verifyTitle')}</h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.04Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>
              {t('privacyPolicy.particular.restrictTitle')}
            </h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.05Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>
              {t('privacyPolicy.particular.deletedTitle')}
            </h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.06Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>
              {t('privacyPolicy.particular.receiveTitle')}
            </h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.07Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <h5 className={styles.h5}>{t('privacyPolicy.particular.lodgeTitle')}</h5>
          </div>
          <div className={styles.paragraph}>
            <li>
              {t('privacyPolicy.particular.08Li')}
            </li>
          </div>
        </ul>
        <h4 className={styles.h4}>
          {t('privacyPolicy.details.title')}
        </h4>
        <p>
          {t('privacyPolicy.details.01Point')}
        </p>
        <p>
          {t('privacyPolicy.details.02Point')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.exercise.title')}</h4>
        <p>
          {t('privacyPolicy.exercise.01Point')}
        </p>
        <h2 className={styles.h2}>
          {t('privacyPolicy.applicability.title')}
        </h2>
        <p>
          {t('privacyPolicy.applicability.01Point')}
        </p>
        <p>{t('privacyPolicy.applicability.02Point')}</p>
        <ul>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>{t('privacyPolicy.applicability.list.01Li')}</li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.applicability.list.02Li')}
            </li>
          </div>
          <div className={styles.list}>
            <p>
              <span>*</span>
            </p>
            <li>
              {t('privacyPolicy.applicability.list.03Li')}
            </li>
          </div>
        </ul>
        <h2 className={styles.h2}>{t('privacyPolicy.cookie.title')}</h2>
        <p>
          {t('privacyPolicy.cookie.01Point')}
          <Link to="/cookie-policy">{t('privacyPolicy.cookie.link')}</Link>
        </p>
        <h3 className={styles.h3}>
          {t('privacyPolicy.additionalTitle')}
        </h3>
        <h4 className={styles.h4}>{t('privacyPolicy.legal.title')}</h4>
        <p>
          {t('privacyPolicy.legal.text')}
        </p>
        <h4 className={styles.h4}>
          {t('privacyPolicy.additionalInformation.title')}
        </h4>
        <p>
          {t('privacyPolicy.additionalInformation.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.systemLogs.title')}</h4>
        <p>
          {t('privacyPolicy.systemLogs.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.information.title')}</h4>
        <p>
          {t('privacyPolicy.information.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.notTrack.title')}</h4>
        <p>
          {t('privacyPolicy.notTrack.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.changes.title')}</h4>
        <p>
          {t('privacyPolicy.changes.text01')}
        </p>
        <p>
          {t('privacyPolicy.changes.text02')}
        </p>
        <h3 className={styles.h3}>{t('privacyPolicy.definitionsTitle')}</h3>
        <h4 className={styles.h4}>{t('privacyPolicy.personalData.title')}</h4>
        <p>
          {t('privacyPolicy.personalData.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.usageData.title')}</h4>
        <p>
          {t('privacyPolicy.usageData.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.user.title')}</h4>
        <p>
          {t('privacyPolicy.user.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.dataSubject.title')}</h4>
        <p>{t('privacyPolicy.dataSubject.text')}</p>
        <h4 className={styles.h4}>{t('privacyPolicy.dataProcessor.title')}</h4>
        <p>
          {t('privacyPolicy.dataProcessor.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.dataController.title')}</h4>
        <p>
          {t('privacyPolicy.dataController.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.website.title')}</h4>
        <p>
          {t('privacyPolicy.website.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.service.title')}</h4>
        <p>
          {t('privacyPolicy.service.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.europeanUnion.title')}</h4>
        <p>
          {t('privacyPolicy.europeanUnion.text')}
        </p>
        <h4 className={styles.h4}>{t('privacyPolicy.legalInformation.title')}</h4>
        <p>
          {t('privacyPolicy.legalInformation.text01')}
        </p>
        <p>
          {t('privacyPolicy.legalInformation.text02')}
        </p>
        <DocFooter />
      </div>
    </main>
  );
};

export default PrivacyPolicy;
