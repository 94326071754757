import useMedia from "../../hooks/useMedia";
import { useState, useEffect } from "react";
import styles from "./styles/industries.module.sass";
import { useTranslation } from "react-i18next";

const IndustriesLine = () => {
  
  const { t, i18n } = useTranslation();
  const casesLineContent = i18n.resolvedLanguage === 'ru' ? "casesLineContentRu" : "casesLineContent"

  const { isDesktop, isTablet } = useMedia();

  const [lines, setLines] = useState([]);

  useEffect(() => {
    const lineData = t('cases.industriesLine', { returnObjects: true })
    if (isDesktop) {
      setLines([lineData.slice(0, 5), lineData.slice(5, lineData.length)]);
    } else if (isTablet) {
      setLines([
        lineData.slice(0, 4),
        lineData.slice(4, 7),
        lineData.slice(7, lineData.length),
      ]);
    } else {
      setLines([
        lineData.slice(0, 2),
        lineData.slice(2, 5),
        lineData.slice(5, 7),
        lineData.slice(7, lineData.length),
      ]);
    }
  }, [isDesktop, isTablet, t]);

  return (
    <div className={styles[casesLineContent]}>
      {lines.map((line, index) => {
        return (
          <ul key={index}>
            {line.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        );
      })}
    </div>
  );
};

export default IndustriesLine;
