import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { fetchPosts } from "features/post-slice";
import { getMedia } from "services/posts";
import { Link } from "react-router-dom";
import VisitButton from "components/UI/button/VisitButton";
import ClockIcon from "../../../images/icons/ClockIcon.svg";
import styles from "./updates.module.sass";
import { useTranslation } from "react-i18next";

const Updates = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { posts } = useSelector((state) => state.posts);

  const [mediaObj, setMediaObj] = useState(null);
  const [newPosts, setNewPosts] = useState([]);

  useEffect(() => {
    dispatch(fetchPosts({ per_page:5 })).then((response) => {
      setNewPosts(response.payload.data);
    });
  }, [dispatch]);

  useEffect(() => {
    const obj = {};
    getMedia({ per_page:5 }).then((resp) => {
      const allImgs = resp.data;
      allImgs.forEach((i) => {
        obj[i.id] = i.guid.rendered;
      });
      setMediaObj(obj);
    });
  }, [setMediaObj]);

  return (
    <>
      <div className={styles.updates}>
        <div className={styles.title}>
          <h2 className={styles.h2}>
            {t('main.updates.title')} <span>{t('main.updates.sTitle')}</span>
          </h2>
          <div className={styles.allArticles}>
            <VisitButton title={t('main.updates.artLink')} href="/blog" />
          </div>
        </div>
        {newPosts &&
          posts
            .slice(0, 2)
            .map(({ slug, date, title, excerpt, featured_media }, index) => {
              const datePosts = new Date(date);
              return (
                <div key={index} className={styles.posts}>
                  <Link to={`/blog/${slug}`}>
                    <div className={styles.postCard}>
                      <div className={styles.postBlock}>
                        <p className={styles.postsDate}>
                          {dateFormat(datePosts, "mmmm d, yyyy")}
                        </p>
                        <div className={styles.postsTime}>
                          <img
                            src={ClockIcon}
                            placeholder="blur"
                            alt="ClockIcon"
                            loading="eager"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: excerpt.rendered.slice(134, 150),
                            }}
                            className={styles.time}
                          />
                          <p>min</p>
                        </div>
                        <h3
                          dangerouslySetInnerHTML={{ __html: title.rendered }}
                          className={styles.h3}
                        />
                      </div>
                      <div className={styles.imgs}>
                        {mediaObj && (
                          <img
                            className={styles.postImgs}
                            src={mediaObj[featured_media]}
                            loading="eager"
                            alt="postMedia"
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
      </div>
    </>
  );
};
export default Updates;
