import { Link } from "react-router-dom";
import nextArrow from "../../../images/icons/ServicesArrowNext.svg";
import prevArrow from "../../../images/icons/ServicesArrowPrev.svg";
import styles from "./styles/arrow.module.sass";

const CustomArrow = ({
  aboveArrText,
  underArrText,
  direction = "right" || "left",
  href,
}) => {
  const arrTextAlign =
    direction && direction === "left"
      ? { textAlign: "right" }
      : { textAlign: "left" };
  return (
    <div className={styles.arrowBox}>
      <div>
        <Link to={href}>
          <p
            className={styles.aboveText}
            style={
              direction === "left"
                ? { textAlign: "right" }
                : { textAlign: "left" }
            }
          >
            {aboveArrText ? aboveArrText : null}
          </p>
          <div className={styles.imageArrow}>
            <img
              src={
                direction === "right"
                  ? nextArrow
                  : direction === "left"
                  ? prevArrow
                  : null
              }
              alt="arrow"
            />
          </div>
          <p className={styles.underText} style={arrTextAlign}>
            {underArrText ? underArrText : null}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default CustomArrow;
