import styles from "./styles/button.module.sass";
import FooterPreloader from "../footerPreloader/FooterPreloader";
import { useTranslation } from "react-i18next";

const SubcsribeButton = ({ onClick, isLoading, disabled=false }) => {
  const { t } = useTranslation();
  return (
    <>
      {isLoading ? (
        <FooterPreloader />
      ) : (
        <button className={styles.arrowFooter} onClick={onClick} disabled={disabled}>
          {t('subscribeButton')}
          <svg
            viewBox="0 0 80 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.1418 19.571L-1.53902e-06 19.571L-1.53902e-06 1L79.9981 1.00003L79.9981 81L61.4271 81L61.4271 31.0012L14.2854 81L-6.27414e-07 68.1412L47.1418 19.571Z"
              fill="#4EB5FF"
            />
          </svg>
        </button>
      )}
    </>
  );
};
export default SubcsribeButton;
